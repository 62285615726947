import axios from 'axios';
import { toast } from 'react-toastify';

const ServerRequest = axios.create({});
ServerRequest.interceptors.response.use(
  (response) => {
    return response;
  },
  (err) => {
    const response = err?.response;
    const config = err?.config;
    const data = response?.data;
    const status = err?.status;
    //ERR_NETWORK
    const message = `ServerRequest.interceptors  => code erreur = ${err.code} =>status erreur= ${err.response?.status}`;
    console.log(message);
    toast.error(message);
    return Promise.reject(err);
  }
);
export default ServerRequest;
