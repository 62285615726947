import {createSlice} from '@reduxjs/toolkit';


const initialGlobalDialogStatus= {
    message:'',
    globalDialogVisible: false,
    typeMessage:''
};

const GlobalDialogStatusSlice = createSlice({
    name: "GlobalDialogStatus",
    initialState: initialGlobalDialogStatus,
    reducers: {
        ShowGlobalDialog(state,action) {
            state.globalDialogVisible = action.payload.etat;
            state.message= action.payload.message;
            state.typeMessage=action.payload.typeMessage;
        },
        HideGlobalDialog(state) {
            state.globalDialogVisible = false;
        },
        VisisbleGlobalDialog(state) {
            state.globalDialogVisible = true;
        },
    }
})

export const globalDialogActions = GlobalDialogStatusSlice.actions;
export const { ShowGlobalDialog, HideGlobalDialog } = globalDialogActions;
export const globalDialogReducer=GlobalDialogStatusSlice.reducer;