import React, {useEffect, useState} from 'react'

import './Card/card.scss'
import Card from './Card/Card'
const ViewRapportUploaded = ({lesRapports,deleteRapport}) => {
  const mainTitre = {
    color: "white",
    display: "flex",
    justifyContent: "center",
    backgroundColor: "brown",
    padding: "2px",
    fontFamily: "Arial"
  };
  return (
    <div className="container mb-5">
      <div>
      <h6 style={mainTitre}>Les rapports déjà disponibles sur la plateforme</h6>
      </div>
          {lesRapports.length>0 && <div className="cards">
          {
            lesRapports?.map((item)=>{
              return(<Card key={item.id} documentInfo={item} deleteRapport={deleteRapport}/>)
              })
          }
          </div>}
    </div>
  )
}

export default ViewRapportUploaded