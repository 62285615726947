
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  Entiter: "",
  AnneePta: "",
  User_id: "",
};

const RensMainParamSlice = createSlice({
  name: 'RensMainParam',
  initialState,
  reducers: {
    setEntiter: (state, action) => {
      state.Entiter = action.payload;
    },
    setAnneePta: (state, action) => {
      state.AnneePta = action.payload;
    },
    setUserId: (state, action) => {
      state.User_id = action.payload;
    },
    getPtaState: (state) => {
      return {
        Entiter: state.Entiter,
        AnneePta: state.AnneePta,
        User_id: state.User_id,
      };
    }
  },
});

// Exporter les actions pour les utiliser dans les composants
export const { setEntiter, setAnneePta, setUserId,getPtaState } = RensMainParamSlice.actions;

// Exporter le reducer pour l'ajouter à notre store
export default RensMainParamSlice.reducer;
