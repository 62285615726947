import React ,{useState,useEffect} from 'react'
import {  useForm, } from 'react-hook-form';
import { UserExistsV2} from '../../DbApi/Users.DbApi';
import { toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";
import { FaEye, FaEyeSlash ,FaHome } from 'react-icons/fa';
import { UpdatePassWord } from '../../DbApi/Users.DbApi';
import Topbar from '../../components/TopBar/Topbar';
import { FcApproval } from "react-icons/fc";
import NewSidebar from '../../components/NewSideBar/NewSidebar';
import '../Dashboard/TableauxDeBord.scss'
const ChangePass_WordV2 = () => {
  const navigate = useNavigate();
  const [emailExists, setEmailExists] = useState(false);
  const [currentUser, setCurrentUser] = useState(undefined);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const { register, handleSubmit, watch, formState: { errors, isSubmitting } } = useForm({ mode: 'onTouched' });

  const checkEmailExists = async (email) => {
      try {
          const response = await UserExistsV2(email);
          return response;
      } catch (error) {
          console.error("Erreur lors de la vérification de l'adresse e-mail :", error);
          return false;
      }
  };

  const onSubmit = async (data) => {
      console.log('on Submit avec datas !', data);
      const re = await UpdatePassWord(data);
      if (re.status === 200) {
          toast.success('Mot de passe modifié avec succès');
          navigate('/');
      } else {
          toast.error('Une erreur est survenue pendant la modification du mot de passe.');
      }
  };

  useEffect(() => {
      const currentUser = sessionStorage.getItem('currentUser');
      setCurrentUser(JSON.parse(currentUser));
  }, []);

  return (
      <div className='flex h-screen items-center justify-center'>
          <form onSubmit={handleSubmit(onSubmit)}>
              <div>
                  <h3 className='text-center my-3 font-bold text-red-800'>Modification du mot de passe</h3>

                  {/* Champ Adresse email */}
                  <div className='flex flex-col'>
                      <label htmlFor="email" className="font-light">Adresse-mail</label>
                      <input
                          type="email"
                          {...register("email", {
                              required: "Une adresse e-mail est requise",
                              validate: async (value) => {
                                  const exists = await checkEmailExists(value);
                                  setEmailExists(exists);
                                  return exists || "Cette adresse e-mail n'existe pas dans notre base de données.";
                              }
                          })}
                          className={`mt-1 p-2 border ${errors.email ? 'border-red-500' : 'border-gray-300'} rounded-md`}
                      />
                      {errors.email && <p className="text-red-500 text-sm mt-1">{errors.email.message}</p>}
                  </div>
                    {/* Champ Nouveau mot de passe */}
                    <div className="flex flex-col mb-4 relative">
                        <label className="font-bold text-gray-700">Nouveau mot de passe :</label>
                        <div className="relative">
                            <input
                                type={showPassword ? "text" : "password"}
                                {...register("newPassword", {
                                    required: "Un nouveau mot de passe est requis"
                                })}
                                className={`w-full mt-1 p-2 pr-10 border ${errors.newPassword ? 'border-red-500' : 'border-gray-300'} rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500`}
                                style={{ height: '2.5rem' }} // Hauteur fixe pour stabiliser l'input
                            />
                            <button
                                type="button"
                                onClick={() => setShowPassword(!showPassword)}
                                className="absolute right-2 top-1/2 -translate-y-1/2 text-gray-500 hover:text-gray-700"
                            >
                                {showPassword ? <FaEyeSlash /> : <FaEye />}
                            </button>
                        </div>
                        {errors.newPassword && <p className="text-red-500 text-sm mt-1">{errors.newPassword.message}</p>}
                    </div>

                    {/* Champ Confirmation du mot de passe */}
                    <div className="flex flex-col mb-4 relative">
                        <label className="font-semibold text-gray-700">Confirmation du nouveau mot de passe :</label>
                        <div className="relative">
                            <input
                                type={showConfirmPassword ? "text" : "password"}
                                {...register("confirmPassword", {
                                    required: "Veuillez confirmer le nouveau mot de passe",
                                    validate: (value) =>
                                        value === watch("newPassword") || "Les mots de passe ne correspondent pas"
                                })}
                                className={`w-full mt-1 p-2 pr-10 border ${errors.confirmPassword ? 'border-red-500' : 'border-gray-300'} rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500`}
                                style={{ height: '2.5rem' }} // Hauteur fixe pour stabiliser l'input
                            />
                            <button
                                type="button"
                                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                                className="absolute right-2 top-1/2 -translate-y-1/2 text-gray-500 hover:text-gray-700"
                            >
                                {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
                            </button>
                        </div>
                        {errors.confirmPassword && <p className="text-red-500 text-sm mt-1">{errors.confirmPassword.message}</p>}
                    </div>


                  <div className='flex items-center justify-center mt-6'>
                      <button
                          type="button"
                          onClick={() => navigate("/")}
                          className='flex items-center mx-2 p-2 bg-gray-600 text-white font-bold rounded-lg text-center'
                      >
                          <FaHome className='mr-2' /> Page d'accueil
                      </button>
                      <button
                          type="submit"
                          disabled={isSubmitting || !emailExists}
                          className="flex items-center mx-2 p-2 bg-green-800 text-white font-semibold rounded-md hover:bg-green-600 disabled:opacity-50"
                      >
                          <FcApproval className='mr-2' /> {isSubmitting ? 'Vérification...' : 'Soumettre'}
                      </button>
                  </div>
              </div>
          </form>
      </div>
  );
};

const ChangePassWordV2 = () => {
  return (
      <>
          <div className="row">
              <Topbar user={null} />
          </div>
          <div className="board">
              <NewSidebar />
              <div className="boardContainer toutHauteur">
                  <ChangePass_WordV2 />
              </div>
          </div>
      </>
  );
};

export default ChangePassWordV2;