import { useEffect, useState } from 'react';
import { GetAllPta } from '../../../DbApi/Pta.DbApi';
import { /*gen4user,*/ GetAllRens, GetAllRensPrisma, TraiterDonneesValider, useSaveReinseignerMutation } from '../../../DbApi/Renseigner.DbApi';
import { GetAllSources } from '../../../DbApi/sources.DBApi';
//import { toast } from 'react-toastify';
import { useQueryClient, useQuery,useMutation,  } from '@tanstack/react-query';
import Select from 'react-select';
import { useSelector,useDispatch } from 'react-redux';
import { setAnneePta,  } from '../../../store/RenseignerMainParam';
import { fetchAllIndicateursPrisma } from '../../../DbApi/indicateurs.DbApi';
import SelectIndicateurV4 from './SelectIndicateursV4';
import ItemIndicateurMobileLayoutV4 from './ItemIndicateurMobileLayoutV4';
import FormIndicateurV4 from './FormIndicateurV4';
import { FaList, FaListUl } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom'
export function IndicateursMobileLayoutV4() {
  const naviguer=useNavigate()
const [showIndicateurForm,setShowIndicateurForm]=useState(false)
const [query, setQuery] = useState('');
const [sources, setSources] = useState([]);
const [selectedItems, setSelectedItems] = useState([]);

const handleCancel = () => {
  console.log('Sélection annulée.');
};


const handleAbort = () => {
console.log('Sélection Abandonnée');
  setSelectedItems([]);
  setOpenSelectIndicateur(false)
};


const {data:LesIndicateurs,isSuccess:ListIndicOK}=useQuery({
  queryKey: ['LesIndicPsEnCours'],
  queryFn: fetchAllIndicateursPrisma,
});
const handleConfirm = (items) => {
    console.log('====  Indicateurs Sélectionnés:', items);
    setOpenSelectIndicateur(false)
    setShowIndicateurForm(true)
    if (items)
    setSelectedItems( items.sort((a, b) => a.idIndicateur.localeCompare(b.idIndicateur))); // Définit la liste entière, incluant l'élément principal et les éléments associés
   
};
const closeIndicateurForm=()=>{
  setShowIndicateurForm(false)
}

  const queryClient = useQueryClient();
 
  const LesYears = useQuery({
    queryKey: ['LesPTA'],
    queryFn: GetAllPta,
  });
  const RensMainParamState = useSelector((state) => state.RensMainParam);
  const { Entiter, AnneePta, User_id } = useSelector((state) => state.RensMainParam);

  const dispatch = useDispatch();
  const defaultValuePta = (unevaleur) => {
   // console.log("fonction defaultValuePta , valeur reçue: ",unevaleur)
    if (LesYears.isSuccess){
      const re= LesYears.data.find((item) => item.idPta === unevaleur);
     // console.log("fonction defaultValuePta ,Résultat: ",re)
      return re
    }
    else return null;
  };
  
  const {data:LesIndicateursRenseigners,isSuccess:LesIndicateursRenseignersIsOK}= useQuery({
    queryKey: ['LesIndicateursRenseignes',RensMainParamState],
    queryFn: GetAllRensPrisma,
  });

 
 
  const re_year=defaultValuePta(AnneePta);
  //console.log("re_year",re_year)
  const [selectedOption, setSelectedOption] = useState(re_year)
  
  const handleYearChange = (option) => {
    setSelectedOption(option);
    dispatch(setAnneePta(option.idPta));
    queryClient.invalidateQueries(['LesIndicateursRenseignes',RensMainParamState]);
  };
  



  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      borderBottom: '1px dotted pink',
      color: state.isSelected ? 'red' : 'blue',
      padding: 20,
    }),
  };
  const searchFilter = (array) => {
    return array.filter((el) =>
      el.LibIndicateur &&   el.LibIndicateur.toLowerCase().includes(query.toLowerCase())
      
    );
  };
  const searchFilterV2 = (array) => {
    return array.filter((el) =>
      el.Indicateur &&   el.Indicateur.Libelle.toLowerCase().includes(query.toLowerCase())
    );
  };
  useEffect(() => {
    async function fetchData() {
      GetAllSources().then((datas) => setSources(datas));
    }

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [re_year]);

  const filtered = searchFilterV2(LesIndicateursRenseigners ? LesIndicateursRenseigners:[]);

  const SearchhandleChange = (e) => {
    setQuery(e.target.value);
  };

  const [isOpenSelectIndicateur, setOpenSelectIndicateur] = useState(false);
  return (
    <>
      <div className="flex flew-row justify-center text-red-900 font-bold ">
        Saisie des Indicateurs du PTA {RensMainParamState.AnneePta}
      </div>
      <button  className="inline-flex px-1 mx-1 my-3 items-center text-indigo-100 bg-green-600 rounded-full hover:bg-green-900 " onClick={() => naviguer("/listindic")}>
      <FaList />
        <span className='mx-2'> Liste des indicateurs</span>

        </button>
      <div className="flex flex-row justify-between items-center mb-3 border-y-2 border-red-500">
        {/* pour selectionner */}
        <div className="mt-3 flex flex-col justify-around items-center ">
          <span>Veuillez choisir une Année</span>
          <Select
            value={selectedOption}
            menuPortalTarget={document.body}
            menuPosition="fixed"
            onChange={handleYearChange}
            options={LesYears?.data}
            getOptionLabel={(option) => option.pta}  // Utilise `LibPt` pour l'étiquette
            getOptionValue={(option) => option.idPta}   // Utilise `idPt` pour la valeur
            theme={(theme) => ({
              ...theme,
              borderRadius: 0,
              colors: {
                ...theme.colors,
                text: '#3599B8',
                font: '#3599B8',
                primary25: '#3599B8',
                primary: '#3599B8',
                neutral80: 'black',
                color: 'black',
              },
            })}
            styles={customStyles}
          />
          <button className={`px-2 py-2 text-white rounded-lg ${
    selectedOption ? 'bg-green-600' : 'bg-gray-400 cursor-not-allowed'
  }`}
          onClick={()=>setOpenSelectIndicateur(true)}
          disabled={!selectedOption}
          >
            Choisir l'indicateur à renseigner
          </button>
        </div>
       <div>
       
       </div>
        

        {/* Nombre d'éléments affichés */}
        <div className="flex items-center space-x-2 text-gray-700 justify-center">
          <FaListUl className="text-red-500" />
          <span>{filtered?.length || 0} {filtered?.length > 1 ?'éléments':'élément'} </span>
        </div>
        
        {/* Pour rechercher */}
        <div className="flex flex-row justify-around items-start ">
          <input
            type="text"
            className="mt-3 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Rechercher"
            onChange={SearchhandleChange}
          />
        </div>{' '}
        {/*FIN Pour rechercher */}
      </div>
     {LesIndicateursRenseignersIsOK && <div className="grid grid-flow-row gap-4 text-neutral-600 grid-cols-1 sm:grid-cols-1  md:justify-items-center md:place-items-center md:items-center md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-4">
        {filtered.map((indicateur, index) => (
          <ItemIndicateurMobileLayoutV4
            indicateur={indicateur}
            key={index}
            idRenseigner={indicateur.idRenseigner}
            sources={sources}
          />
        ))}
      </div>}
      {isOpenSelectIndicateur && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
          <div className="fixed inset-0 bg-black opacity-50"></div>
          <div className="bg-white rounded-lg shadow-lg p-6 w-full max-w-md relative">
            <button
              onClick={()=>setOpenSelectIndicateur(false)}
              className="absolute top-2 right-2 text-red-500 hover:text-red-700"
            >
              &times;
            </button>
            {ListIndicOK && 
            <div>
              <SelectIndicateurV4
                LaList={LesIndicateurs} 
                onCancel={handleCancel} // Gérer l'annulation
                onConfirm={handleConfirm} // Gérer la confirmation
                onAbort={handleAbort}
              />
            </div>}
          </div>
        </div>
      )}
      { 
            <FormIndicateurV4
              items={selectedItems}
              isOpen={showIndicateurForm}
              onClose={closeIndicateurForm}
            /> 
      }
       
      
    </>
  );
}
