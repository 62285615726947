import React from 'react';
import useOnlineStatus from "../../helpers/useOnlineStatus";

const OnLineBar = () => {
    const isOnline = useOnlineStatus();
   
  return (
  <>
   <style>
        {`
          @keyframes clignoter {
            0%, 100% { opacity: 1; }
            50% { opacity: 0; }
          }

          .animate-blink {
            animation: clignoter 0.8s infinite;
            transition: none;
          }
        `}
      </style>
     <div className = { isOnline ? "bg-green-500 text-white rounded-sm p-0.5 font-bold": "text-red-500 bg-[wheat] animate-blink"}>
       {isOnline ? "Connecté au serveur" : "déconnecté du serveur"}
    </div>
    
  </>
  )
}

export default OnLineBar