/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { GetAllSources } from '../../DbApi/sources.DBApi';
import Select from 'react-select';
import { useLocation } from 'react-router-dom';
import Topbar from '../../components/TopBar/Topbar';
import Button from '@mui/material/Button';
import DoneOutlineIcon from '@mui/icons-material/DoneOutline';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import './SetSourcesVerifications.css';
const SetSourcesVerifications = () => {
  let currentUser = sessionStorage.getItem('currentUser');
  //const user=location.state
  const user = JSON.parse(currentUser);

  const location = useLocation();
  const navigate = useNavigate();
  const rowData = location.state;
  const [sources, setSources] = useState([]);
  const [selectedOption, SetSelectedOption] = useState(null);

  const handleChange = (selectedOption) => {
    SetSelectedOption(selectedOption);
    console.log('selectedOption', selectedOption);
  };

  useEffect(() => {
    async function fetchData() {
      setSources(await GetAllSources());
    }
    fetchData();
  }, []);

  return (
    <div className="d-flex flex-row justify-content-center align-items-center">
      <div className="d-flex flex-column align-items-center">
        <div className="col-12 ">
          <Topbar user={user} />
        </div>
        <div className="col-8 mt-5 mb-1">
          Résultat : {rowData.CodeResultat} -- {rowData.LibResultat}
        </div>
        <div className="col-8 mt-3 mb-1 textColorBrown">
          Indicateur : {rowData.Indicateurs_id} -- {rowData.LibIndicateur}
        </div>

        <div className="col-8 mt-3 mb-3">
          <strong>
            Veuillez choisir une ou plusieurs sources de vérification
          </strong>
        </div>
        <div className="col-8">
          <Select
            //defaultValue={[colourOptions[2], colourOptions[3]]}
            isMulti={true}
            name="Sources"
            options={sources}
            className="basic-multi-select"
            classNamePrefix="select"
            onChange={handleChange}
          />
        </div>

        <div className="d-flex flex-row justify-content-center">
          <div className="col-6 mr-3 mt-3">
            <Button variant="contained" color="success" onClick={() => {}}>
              {' '}
              <DoneOutlineIcon color="action" /> Valider
            </Button>
          </div>
          <div className="col-6 mr-3 mt-3">
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                navigate(-1);
              }}
            >
              {' '}
              <ArrowBackIcon color="action" /> Retour
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SetSourcesVerifications;
