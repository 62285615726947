import { useForm } from 'react-hook-form';
import { useEffect } from 'react';

const EditForm = ({ item, onSubmit, onCancel, onPrevious, hasNext, hasPrevious }) => {
  const { register, handleSubmit, setValue } = useForm();

  // Utilisation de useEffect pour initialiser la valeur de 'name' à chaque changement d'item
  useEffect(() => {
    setValue('name', item.name);
  }, [item, setValue]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <h2>Édition de {item.name}</h2>
      <input {...register('name')} placeholder="Nom" />
      <button type="submit">Valider</button>
      {hasPrevious && <button type="button" onClick={onPrevious}>Précédent</button>}
      {hasNext && <button type="button" onClick={handleSubmit(onSubmit)}>Suivant</button>}
      <button type="button" onClick={onCancel}>Annuler</button>
    </form>
  );
};

export default EditForm;
