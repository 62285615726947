import Select from 'react-select';
import React, { useState } from 'react';
import ShowIndicateurDetail from '../ShowIndicateurDetail';
import { useSelector } from 'react-redux';
import { useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { extractDenominators, extractFieldsFromFormula } from '../../../DbApi/Renseigner.DbApi';

const SelectIndicateurV4 = ({ LaList, onCancel, onAbort,onConfirm  }) => {
    const [selectedOption, setSelectedOption] = useState(null);
    const [selectedDetail, setSelectedDetail] = useState(null); // Pour gérer l'option pour la modale
    const [isModalOpen, setIsModalOpen] = useState(false); // Gérer l'état de la modale
    const [items, setItems] = useState([]);

    const queryClient = useQueryClient();
   
    const RensMainParamState = useSelector((state) => state.RensMainParam);
    // Vérification si LaList est un tableau et transformation en options pour react-select
    const options = Array.isArray(LaList) ? LaList.map(item => ({
        value: item.idIndicateur, // Valeur pour react-select
        //label: `${item.idIndicateur} - ${item.Libelle}`, // Simple label
        label: `${item.Libelle}`,
    })) : [];

    // Fonction de filtrage personnalisée
    const customFilter = (option, searchValue) => {
        const searchLower = searchValue.toLowerCase(); // Convertir en minuscule pour la recherche
        return (
            option.value.toLowerCase().includes(searchLower) || 
            option.label.toLowerCase().includes(searchLower) // Recherche dans le label simple
        );
    };
    const handleChange = (selected) => {
        const IndicRenseigners= queryClient.getQueryData(['LesIndicateursRenseignes',RensMainParamState])
        let deja
        if (selected)
         deja=IndicRenseigners?.find((el=>  el.Indicateurs_id===selected.value))
        if (deja) {
            setSelectedOption(null); // Réinitialiser la sélection
            setItems([])
            toast.error("Cet indicateur est déjà renseigné");
        } else {
            let item
            if (selected)
            item=LaList?.find((el=>  el.idIndicateur ===selected.value))
            if (item?.Formule){
             
                let fields=extractFieldsFromFormula(item.Formule) // extrait les champs de la formule
                let denominateur=extractDenominators(item.Formule)
                for (const  field of fields) {
                    let toAdd=LaList?.find((el=>  el.idIndicateur===field))
                    setItems((prevItems) => [...prevItems, toAdd]);
                }
            } else {
                let item
                if(selected)
                item=LaList?.find((el=>  el.idIndicateur===selected.value))
                setItems([item]);   
            }
            setSelectedOption(selected); // Mettre à jour l'état avec l'option sélectionnée
        }
       
    };
    const handleCancel = () => {
        setSelectedOption(null); // Réinitialiser la sélection
        setItems([])
        if (onCancel) onCancel(); // Appeler la fonction onCancel si elle est passée
    };

    const handleAbort=() =>{
        setItems([])
        if (onAbort) onAbort()
    }

    // Fonction pour confirmer la sélection
    const handleConfirm = () => {
        if (onConfirm) onConfirm(items); // Appeler la fonction onConfirm avec la sélection
    };
        // Fonction pour afficher les détails
        const handleShowDetails = (item) => {
           const element= LaList.find(el => el.idIndicateur ===item.value); 
            setSelectedDetail(element);
            setIsModalOpen(true); // Ouvrir la modale
        };
    
        // Fonction pour fermer la modale
        const closeModal = () => {
            setIsModalOpen(false);
            setItems([])
            setSelectedDetail(null);
        };
        
    return (
        <div>
            <h1 className="text-center font-bold text-xl">Liste des indicateurs</h1>
            <div className=" bg-gray-100 p-4 w-full"> 
                <Select
                    options={options}
                    isClearable
                    isSearchable
                    filterOption={customFilter} // Utilisation de la fonction de filtrage personnalisée
                    className="basic-single"
                    classNamePrefix="select"
                    onChange={handleChange} // Gestionnaire d'événements pour le changement
                    value={selectedOption} // Valeur du sélecteur
                    formatOptionLabel={(option) => (
                        <div className="flex justify-between items-center">
                            <span>{option.label}</span>
                            <button
                                className="text-blue-500 underline ml-4"
                                onClick={(e) => {
                                    e.stopPropagation(); // Empêcher la sélection de l'option quand on clique sur "Détail"
                                    handleShowDetails(option);
                                }}
                            >
                                Détail
                            </button>
                        </div>
                    )}
                    styles={{
                        control: (provided) => ({
                            ...provided,
                            borderColor: 'gray',
                            boxShadow: 'none',
                            '&:hover': {
                                borderColor: 'gray',
                            },
                        }),
                        option: (provided, state) => ({
                            ...provided,
                            backgroundColor: state.isSelected ? '#E5E7EB' : state.isFocused ? '#F3F4F6' : 'white',
                            color: state.isSelected ? 'black' : 'gray',
                            borderBottom: '1px solid #E5E7EB',
                            padding: '8px',
                        }),
                        menu: (provided) => ({
                            ...provided,
                            borderRadius: '0.5rem',
                            boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
                        }),
                    }}
                />
            </div>
            <div className="flex justify-between mt-4">
                <button
                    className="bg-red-500 text-white px-4 py-2 rounded"
                    onClick={handleCancel} // Gérer l'annulation
                >
                    Annuler
                </button>

                <button
                    className="bg-red-500 text-white px-4 py-2 rounded"
                    onClick={handleAbort} // Gérer l'abandon
                >
                    Abandonner
                </button>

                <button
                    className="bg-green-500 text-white px-4 py-2 rounded"
                    onClick={handleConfirm} // Gérer la confirmation
                    disabled={!selectedOption} // Désactiver si aucune option n'est sélectionnée
                >
                    Confirmer
                </button>
            </div>
            {/* Modale pour afficher les détails d'un indicateur par un composant */}
            <ShowIndicateurDetail
                isOpen={isModalOpen}
                indicateur={selectedDetail}
                onClose={closeModal}
            />
        </div>
    );
};

export default SelectIndicateurV4;
