import React, { useEffect, useState } from 'react'
//import { MenuItem,Select as MuiSelect ,FormControl,InputLabel } from '@mui/material';
import { Button } from '@mui/material';
import { GetAllPta } from '../../DbApi/Pta.DbApi';
import Select from 'react-select';
import TableIndicateurs from './TableIndicateurs';
import { GetAllRensV2 } from '../../DbApi/Renseigner.DbApi';
import ProtectContent from '../../helpers/ProtectContent';
import { Head } from 'react-static';
import LesTypesValues from '../../components/TypesValues/LesTypesValues';
import './AdminPta.css';
const LesEntiters=[
  {"value":"1","label":"SCDIH Caritas Cotonou","typeEntiterId":"1"},
  {"value":"2","label":"Caritas Diocésaine de Dassa","typeEntiterId":"1"},
  {"value":"3","label":" Caritas Diocésaine de Djougou","typeEntiterId":"1"},
  {"value":"4","label":" Caritas Diocésaine de Kandi","typeEntiterId":"1"},
  {"value":"5","label":" Caritas Diocésaine de Lokossa","typeEntiterId":"1"},
  {"value":"6","label":" Caritas Diocésaine de Natitingou","typeEntiterId":"1"},
  {"value":"7","label":" Caritas Diocésaine de N'Dali","typeEntiterId":"1"},
  {"value":"8","label":" Caritas Diocésaine de Parakou","typeEntiterId":"1"},
  {"value":"9","label":" Caritas Diocésaine de Abomey","typeEntiterId":"1"},
  {"value":"10","label":" Caritas Diocésaine de Porto-Novo","typeEntiterId":"1"},
 /*  {"id":"11","label":"Direction Nationale de Caritas Bénin","typeEntiterId":"0"}, */
  {"value":"12","label":"Tous","typeEntiterId":"0"}
  ]
const LesValeurs=[{"value":"0", "label": "Nulle"}, {"value":"1", "label": "Non Nulle"}, {"value":"01", "label": "Toutes"}]
const MainComponent = () => {
   const [LesAnnees,SetLesAnnees]=useState([]) 
   const [annePta,SetAnnePta]=useState('10')
   const [selectedOption, setSelectedOption] = useState(null);
   const [Entiter, setEntiter] = useState('12');
   const [typeValeur, settypeValeur] = useState('01');
   const [RowsDatas, setRowsDatas] = useState([]);

    
   const handleChange = (selectedOption) => {
    setSelectedOption(selectedOption)
    SetAnnePta(selectedOption.value)
    //console.log("selectedOption :",selectedOption)
   }
   const handleChangeEntiter = (selectedOption) => {
    setEntiter(selectedOption.value)
   // console.log(" Entiter selectedOption :",selectedOption)
   }
   const handleChangeValeur = (selectedOption) => {
    settypeValeur(selectedOption.value)
    //console.log("selectedOption :",selectedOption)
   }
   const OnTypeValueChange=(e) => {
    settypeValeur(e.target.value)
   }

  useEffect(() => {
    async function fetchData() {
        const lesYears=await GetAllPta()
        let Encours = lesYears.find( item => item.enCours === 1);
        //SetAnnePta(Encours.value)
        
        let param={ Entiter:Entiter,AnneePta:annePta, TypeValue: typeValeur}
        let tempo =await GetAllRensV2(param);
        console.log('tempo ',tempo)
        setRowsDatas(tempo)
        console.log(" UseEffect Calll, RowsDatas=",RowsDatas)
        SetLesAnnees(lesYears)
    }
    fetchData();
  }, [annePta,Entiter,typeValeur])

  const columns=[
        {title: "Code",field: "Indicateurs_id",  editable:'never'},
        {title: "Structure",field: "NomEntiter",  editable:'never'},
        {title: "idRenseigner", field: "idRenseigner", editable:'never', hidden: true},
        {title: "CodeIndicateur", field: "Indicateur", editable:'never', hidden: true},
        {title: "Indicateur", field: "Libelle", editable:'never'},
        {title: "Valeur", field: "Valeur",type: 'numeric'},
        {title: "Entiter_id", field: "Entiter_id", editable:'never', hidden: true},
        {title: "PTA_id", field: "PTA_id", editable:'never', hidden: true},
  ]
  const [selectedRow, setSelectedRow] = useState(null);
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      borderBottom: '1px dotted pink',
      color: state.isSelected ? 'red' : 'blue',
      padding: 20,
    })
  }
 
  
  const options={
    exportButton: true,
    pageSize: 65,
    pageSizeOptions: [65, 100, 150],
    actionsColumnIndex: -1,
    rowStyle: rowData => ({
      backgroundColor: (selectedRow === rowData.tableData.id) ? '#EEE' : '#FFF'
    }),
    headerStyle: {
        position: "sticky",
        top: "0",
        backgroundColor:'brown',
        fontWeight: 'bold',
        color: 'white',
      },
    maxBodyHeight: "500px",
  }
  
 
  return (
  <>
    <Head>
      <title>Caritas Bénin| Plateforme de Suivi-Evaluation de Redevabilité et d'apprentissage | Evaluation Plan Stratégique</title>
    </Head>
    <div className='bgc mt-3'><strong>Saisie des Indicateurs du PTA {annePta}</strong></div>
    <div className='ligne'>
      
    <div> Veuillez choisir une Année
    <Select
        defaultValue={annePta}
        menuPortalTarget={document.body}
        menuPosition="fixed"
        onChange={handleChange}
        options={LesAnnees}
        theme={(theme) => ({
            ...theme,
            borderRadius: 0,
            colors: {
            ...theme.colors,
              text: '#3599B8',
              font:'#3599B8',
              primary25: '#3599B8',
              primary: '#3599B8',
              neutral80: 'black',
              color: 'black',
            },
          })}
          styles={customStyles}
        />
    </div> 

    
    <div >Un diocèse / ou TOUS
    <Select
        defaultValue={"12"}
        menuPortalTarget={document.body}
        menuPosition="fixed"
        onChange={handleChangeEntiter}
        options={LesEntiters}
        />
    </div> 
    <div>
    <LesTypesValues items={LesValeurs} nom={"lesValeurs"} onChange={OnTypeValueChange} LeTitre={"Type de valeur ?"}/>
    </div>
    </div>
      <div className='col-md-12'>
      <TableIndicateurs SetDatas={setRowsDatas} datas={RowsDatas} columns={columns} options={options} />
      </div>
  </>
  )
}

export default ProtectContent(MainComponent)