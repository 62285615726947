import React from 'react';

const ShowIndicateurDetail = ({ isOpen, indicateur, onClose }) => {
    if (!isOpen || !indicateur) return null; // Si la modale n'est pas ouverte ou qu'il n'y a pas de détail, on ne rend rien

    return (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-800 bg-opacity-50">
            <div className="bg-white rounded-lg p-6 w-96 shadow-lg ">
                <div className='overflow-y-auto h-screen'>
                <h2 className="text-lg font-bold mb-4 text-center">
                    Détails de l'indicateur choisie
                </h2>
                <p><strong>Libellé :</strong> {indicateur.Libelle}</p>
                {/* Ajoutez ici les autres détails à afficher */}
                <p><strong>Description :</strong> {indicateur.Description}</p>
                {/* Ajoutez plus de détails selon vos besoins */}
              
                <p>
                <strong>A des sous-Champ ? :</strong> {indicateur.IndicateurSousChamp?.length > 0 ? "Oui" : "Non"}
                </p>

                <p>
                <strong>Mode :</strong> {indicateur.Formule ? "Automatique" :indicateur.IndicateurSousChamp?.length > 0 ?"Somme des Sous Champs" :"Saisie"}
                </p>


                <p><strong>Résultat :</strong> {indicateur.Resultat.Libelle}</p>
                
                <p><strong>Objectif  :</strong> {indicateur.Resultat.Objectif.Libelle}</p>
                <p><strong>O.S :</strong> {indicateur.Resultat.Objectif.orientation.Libelle}</p>

                <div className="flex justify-end mt-4">
                    <button
                        className="bg-red-500 text-white px-4 py-2 rounded"
                        onClick={onClose}
                    >
                        Fermer
                    </button>
                </div>
                </div>
            </div>
        </div>
    );
};

export default ShowIndicateurDetail;
