import { useSelector } from "react-redux";
import { GetRensValue } from "../../DbApi/Renseigner.DbApi";
import { useEffect, useState } from 'react';

const ValeursSousChamp = ({ isOpen, indicateur, onClose }) => {
  const [LesValeurs, setLesValeurs] = useState([]);
  const { Entiter, AnneePta, User_id } = useSelector((state) => state.RensMainParam);

  const fetchSousChampValeurs = async () => {
    if (!indicateur || !indicateur.Indicateur?.IndicateurSousChamp) return;

    const promises = indicateur.Indicateur.IndicateurSousChamp.map(async (item) => {
      const param = {
        User_id,
        Entiter: Entiter,
        AnneePta,
        Indicateur: item.Indicateur_ID,
        sousChamp: item.SousChamp_ID
      };
      const retour = await GetRensValue(param);
      console.log("valeur sous champ",item.sousChamp.LibelleSousChamp,retour )
      return {
        Indicateur_ID: item.Indicateur_ID,
        LibSousChamp: item.sousChamp.LibelleSousChamp,
        Valeur: retour ? retour.valeur : 0
      };
    });

    const result = await Promise.all(promises);
    console.log("LesValeurs obtenues :", result);
    setLesValeurs(result);
  };

  useEffect(() => {
    if (isOpen && indicateur) {
      console.log("Fetching sous-champ valeurs...");
      fetchSousChampValeurs();
    }
  }, [isOpen, indicateur]);

  if (!isOpen || !indicateur) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-800 bg-opacity-50">
      <div className="bg-white rounded-lg p-6 w-96 shadow-lg">
        <div className="bg-white rounded-lg shadow-lg p-2 w-full max-w-md relative">
          <button
            onClick={onClose}
            className="absolute top-0 right-2 text-red-500 hover:text-red-700"
          >
            &times;
          </button>
        </div>
        <h1 className="text-lg font-bold mb-4 text-center">{indicateur.Indicateur.Libelle}</h1>
        <h1 className="text-sm font-bold mb-4 text-center">Les sous champs et leurs valeurs</h1>
        <div className="grid grid-cols-2 gap-4">
          <div className="font-bold">Nom du Sous-Champ</div>
          <div className="font-bold text-right">Valeur</div>
          {LesValeurs.length > 0 ? (
            LesValeurs.map((el, index) => (
              <div key={index} className="contents">
                <div className="px-3 py-1">{el.LibSousChamp}</div>
                <div className="px-3 py-1 text-right">{el.Valeur}</div>
              </div>
            ))
          ) : (
            <p className="text-center col-span-2 text-gray-500">Chargement des valeurs...</p>
          )}
        </div>
        <div className="text-center">
          <button className="font-bold text-red-700" onClick={onClose}>Fermer</button>
        </div>
      </div>
    </div>
  );
};

export default ValeursSousChamp;
