import { useEffect, useState } from 'react';
import { useForm, Controller, } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useQueryClient, } from '@tanstack/react-query';
import { evaluerFormuleV4, extractFieldsFromFormula, fetchIndicValue, fetchSousChampValues, fetchValuesForFields,  useSaveMainIndicMutation, useSaveReinseignerMutation } from '../../../DbApi/Renseigner.DbApi';
import { FaArrowRight,FaArrowLeft } from 'react-icons/fa';
import { useUpdateMainIndic } from '../../../DbApi/Renseigner.DbApi';
const FormIndicateurV4 = ({ items=[],isOpen,  onClose }) => {
  const { updateMainIndic } = useUpdateMainIndic();
  const [calculatedValue, setCalculatedValue] = useState(null);
  const [sumSousChamp, setSumSousChamp] = useState(0); // Initialise à 0
  const { AnneePta, Entiter, User_id } = useSelector((state) => state.RensMainParam);
  const [currentIndex, setCurrentIndex] = useState(0);
  const indicateur=items[currentIndex]
  const queryClient = useQueryClient();
  const { control, handleSubmit,clearErrors, reset, watch,formState :{ errors, isDirty },getValues} = useForm();
  const {mutateAsync:saveRenseigner}=useSaveReinseignerMutation()
  const {mutateAsync:saveMainIndic}=useSaveMainIndicMutation()
  
  const handlePrevious = () => {
    handleSubmit((data) => {
      if(isDirty) handleSave(data);
      if (currentIndex >0) setCurrentIndex((prev) => prev - 1);
    })();
  };
  
  const handleNext = () => {
    handleSubmit((data) => {
     if(isDirty) handleSave(data);
     if (currentIndex< items.length - 1) setCurrentIndex((prev) => prev + 1);
    })();
  };
 const hasNext=currentIndex < items.length - 1
 const hasPrevious=currentIndex > 0

 const handleEnd=async ()=>{
  if (currentIndex === items.length - 1) {
    toast.success('Appliquer les valeurs quand il y a plusieurs items liés par une formule')
    reset(); 
    clearErrors();
    setCurrentIndex(0);
    onClose()
    let maindata=await updateMainIndic(items, AnneePta, Entiter, User_id)
    await SAVEmainIndicValues(maindata)
  }
 }

 const SAVEmainIndicValues=async (dataForMainIndic)=>{
      dataForMainIndic.AnneePta=AnneePta;
      dataForMainIndic.Entiter=Entiter;
      dataForMainIndic.User_id=User_id;
      await saveMainIndic({datas:dataForMainIndic})
 }

 const handleSave = async (data) => {
    data.AnneePta=AnneePta
    data.Entiter=Entiter
    data.User_id=User_id  
    let xx=getValues()
    console.log("getValues =>",xx)
    console.log(indicateur.idIndicateur, "valeur",data)
    await saveRenseigner({idIndicateur:indicateur.idIndicateur,data:data})
     reset(getValues());
      if(items.length ===1) // cas où il y a un seul indicateur
       {
        onClose()
        reset(); 
        clearErrors();
        //est-ce que le dernier caractère de idIndicateur finit par "a" ou "b"
       let lastChar= indicateur.idIndicateur.slice(-1);
       if (lastChar==='a'|| lastChar==="b") {
        const withoutLastChar =indicateur.idIndicateur.slice(0, -1);
        let lesItems=[]
        let id1=withoutLastChar+'a'
        const ListeIndic= queryClient.getQueryData(['LesIndicPsEnCours'])
        let item1=ListeIndic?.find((el=>  el.idIndicateur===id1))
        lesItems.push(item1)
        let id2=withoutLastChar+'b'
        let item2=ListeIndic?.find((el=>  el.idIndicateur===id2))
        lesItems.push(item2)
        console.log('lesItems',lesItems)
        let mainIndicData=await updateMainIndic(lesItems, AnneePta, Entiter, User_id)
        await SAVEmainIndicValues(mainIndicData)
       }
       }
  };
  
  useEffect(() => {
    const loadValues = async () => {
      if (indicateur) {
       
        let defaultValues = {};
        let initialSum = 0;
        if (indicateur.IndicateurSousChamp?.length > 0) {
          const sousChamps = await fetchSousChampValues(indicateur.idIndicateur, indicateur.IndicateurSousChamp, Entiter, User_id, AnneePta);
          // Créer les valeurs par défaut pour chaque sous-champ et calculer la somme initiale
          console.log("sousChamps = ",sousChamps)
          defaultValues.sousChamps = {};
          Object.entries(sousChamps).forEach(([key, value]) => {
            const valeur = Number(value.valeur) || 0;
            defaultValues.sousChamps[key] = {
              idSousChamp: key.split('_')[1],
              valeur: valeur
            };
            if (!indicateur.formule)
            initialSum += valeur; // Ajouter chaque valeur à la somme initiale
          });
          if (!indicateur.formule)
          setSumSousChamp(initialSum); // Initialiser sumSousChamp avec la somme des valeurs

        } else {
          // Si pas de sous-champs, on initialise l'indicateur
          const laValeur = await fetchIndicValue(indicateur.idIndicateur, Entiter, User_id, AnneePta);
          defaultValues.Indicateur = {
            idIndicateur: indicateur.idIndicateur,
            valeur: laValeur || 0
          };
         // initialSum = laValeur || 0;
        }

        reset(defaultValues); // Utilise reset pour définir les valeurs initiales du formulaire en une seule opération
        //console.log("defaultValues=>",indicateur?.idIndicateur,defaultValues)
        if (indicateur.formule) {
          // Appliquer la formule si elle est présente
          const fields = extractFieldsFromFormula(indicateur.formule);
          const values = await fetchValuesForFields(fields, AnneePta);
          console.log("fields values=",values)
          const result = evaluerFormuleV4(indicateur.formule, values);
          setCalculatedValue(result);
        }
      }
    };
    const initializeFormData = async () => {
      for (const indicateur of items) {
        await loadValues(indicateur); 
      }
      //setFormData(allData); // Mettre à jour `formData` avec toutes les valeurs
    };
    if (items.length > 0) {
      initializeFormData();
    }
  }, [items,indicateur, AnneePta, reset]);

  const SommeSousChamps = () => {
    if (indicateur.formule) {

    } else {
      const valeursFormulaire = watch();
    const ssChamp = valeursFormulaire.sousChamps || {};
    const sommeValeurs = Object.values(ssChamp).reduce((acc, item) => acc + item.valeur, 0);
    setSumSousChamp(sommeValeurs);
    }
  };

  if (!isOpen) return null;

  const handleSousChampChange = (key, value) => {
    SommeSousChamps(); // Recalculer la somme des sous-champs à chaque modification
    const updatedSousChamps = indicateur.IndicateurSousChamp.map((sousChamp) => ({
      codeSousChamp: sousChamp.sousChamp.idSousChamp,
      valeur: sousChamp.sousChamp.idSousChamp === key.split('_')[1] ? value : sousChamp.valeur || 0,
    }));

    const totalValue = updatedSousChamps.reduce((acc, item) => acc + (item.valeur || 0), 0);
    setCalculatedValue(totalValue);
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-800 bg-opacity-50">
      <div className="bg-white rounded-lg p-6 w-96 shadow-lg">
        <form key={indicateur?.idIndicateur} onSubmit={handleSubmit(handleSave)} className="space-y-6 ">
          <div className='overflow-y-auto'>
          <h2 className="font-bold text-center">{indicateur.Libelle}</h2>
          
          {indicateur.IndicateurSousChamp?.length > 0 ? (
            <>
              {indicateur.IndicateurSousChamp.map((sousChamp, index) => {
                const key = `${indicateur.idIndicateur}_${sousChamp.sousChamp.idSousChamp}`;
                return (
                  <div key={index} className="flex items-center space-x-4">
                    <label htmlFor={key} className="font-light">{sousChamp.sousChamp.LibelleSousChamp}</label>
                    <Controller
                      name={`sousChamps[${key}]`}
                      control={control}
                      rules={{ 
                        required: "Une valeur est requise",
                        validate: value => {
                        return  value?.valeur > 0 || "La valeur doit être supérieure à 0"
                        }
                    }}
                      render={({ field }) => (
                        <input
                          type="number"
                          className="border p-2 w-20"
                          placeholder="Valeur"
                          value={field.value?.valeur || 0}
                         
                          onChange={(e) => {
                            const value = parseInt(e.target.value) || 0;
                            field.onChange({ ...field.value, valeur: value });
                            console.log("onChange, field.value : ",field.value)
                            handleSousChampChange(key, value);
                          }}
                        />
                      )}
                    />
                    {errors.sousChamps?.[key] && (<p className="text-red-500 text-sm">
                    {errors.sousChamps[key].message}
                   </p>)}
                  </div>
                );
              })}
              <div className="mt-4 p-4 bg-blue-100 text-blue-700 rounded">
                <span>Valeur Totale de l'indicateur : </span>
                <span>{sumSousChamp}</span>
              </div>
            </>
          ) : (
            <Controller
                name="Indicateur"
                control={control}
                rules={{ 
                  required: "Une valeur est requise",
                  validate: value => value.valeur > 0 || "La valeur doit être supérieure à 0"
                }}
                render={({ field }) => (
                  <div>
                    <input
                      {...field}
                      type="number"
                      className="border p-2 w-20"
                      placeholder="Valeur"
                      disabled={!!indicateur.formule}
                       value={calculatedValue || field.value?.valeur || 0}
                      onChange={(e) => {
                        const value = parseInt(e.target.value) || 0;
                         field.onChange({ ...field.value, valeur: value }); 
                       
                      }}
                    />
                    {/* Message d'erreur */}
                    {errors.Indicateur && (
                      <p className="text-red-500 text-sm">
                        {errors.Indicateur.message}
                      </p>
                    )}
                  </div>
                )}
              />
          )}

          {indicateur.formule && (
            <p className="text-sm text-gray-600">Valeur calculée : {calculatedValue}</p>
          )}
          <div className='flex flex-row'>
          {hasPrevious && <button type="button" onClick={handlePrevious} 
          className={`flex text-white px-4 py-2 rounded-md
            ${isDirty ||!hasPrevious ? 'bg-gray-400 cursor-not-allowed' : 'bg-green-600' }`}
          disabled={isDirty ||!hasPrevious}
          ><FaArrowLeft className='mx-2'/> Précédent</button>}
          
          {hasNext && <button type="button" onClick={handleNext} 
          className={`flex text-white px-4 py-2 rounded-md ${ isDirty || ! hasNext ? 'bg-gray-400 cursor-not-allowed' : 'bg-green-600'}`}
          disabled={isDirty || ! hasNext}
          > <FaArrowRight className='mx-2'/> Suivant</button>}


    
          </div>
          <div className="flex flex-wrap justify-center space-x-4">
            <button type="button" className="bg-red-500 text-white px-4 py-2 rounded-md" onClick={onClose}>
              Annuler
            </button>
           
            <button type="submit" 
            className={` text-white px-4 py-2 rounded-md ${ !isDirty ? 'bg-gray-400 cursor-not-allowed':'bg-blue-500'}`}
            disabled={!isDirty}
            >
              Enregistrer
            </button>

            {(hasNext ||hasPrevious) && <button type="button" onClick={handleEnd} 
          className={`flex text-white px-4 py-2 rounded-md ${ currentIndex < items.length -1||(currentIndex === items.length -1 && isDirty) ? 'bg-gray-400 cursor-not-allowed' : 'bg-green-600'}`}
          disabled={currentIndex < items.length -1||(currentIndex === items.length -1 && isDirty)}
          > <FaArrowRight className='mx-2'/> Valider</button>}
          </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default FormIndicateurV4;
