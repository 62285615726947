import React from 'react'
import ImgWord from '../../../assets/images/word.png'
import ImgPdr from '../../../assets/images/pdf.png';

const Card = ({documentInfo,deleteRapport}) => {
    var extention= documentInfo.NomFichier.split('.').pop()
    var image
    if (extention==='pdf') 
        image= ImgPdr 
    else image=ImgWord
  return (
    <div className="card">
    <img src={image} alt={`Rapport Annuel ${documentInfo.Pta}`} />
    <div className="card-body">
      <h2>{`Rapport Annuel ${documentInfo.Pta}`}</h2>
      <p>
       {documentInfo.Description}
      </p>
      {/* <h5>Autre Texte / Button</h5> */}
      <div>
      <button className="btn btn-danger" onClick={() => deleteRapport(documentInfo.id)}>Supprimer ce rapport</button>
      </div>
    </div>
  </div>
  )
  
}

export default Card