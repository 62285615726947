import React from 'react'
import './style.css'
const LesTypesValues = ({items,nom,onChange,LeTitre}) => {
   const onChangeValue=(event) =>{
        console.log(event.target.value);
      }
  return (
    <>
    {LeTitre}
    <div onChange={onChange} className="ligne">

    {
        items.map((item)=>(
        <div>
            <input type="radio" value={item.value} name={nom} /> <span>{item.label}</span>
        </div>
        ))  
    }
    </div>
    </>
  )
}

export default LesTypesValues