import React, { useEffect, useState } from 'react';
//import { MenuItem,Select as MuiSelect ,FormControl,InputLabel } from '@mui/material';

import { GetAllPta } from '../../DbApi/Pta.DbApi';
import Select from 'react-select';
import TableIndicateurs from './TableIndicateurs';
import { GetAllRens, GetAllRens4User } from '../../DbApi/Renseigner.DbApi';
import ProtectContent from '../../helpers/ProtectContent';
import { Head } from 'react-static';
import './Evalpta.css';
import { useQueryClient, useQuery } from '@tanstack/react-query';
import { useSelector,useDispatch } from 'react-redux';
import { setAnneePta,  } from '../../store/RenseignerMainParam';

const MainComponent = () => {
  const queryClient = useQueryClient();
  const [RowsDatas, setRowsDatas] = useState([]);
  const dispatch = useDispatch();
  const RensMainParamState = useSelector((state) => state.RensMainParam);

 

  const handleChange = (selectedOption) => {
    dispatch(setAnneePta(selectedOption.value));
 
  };

  const LesYears = useQuery({
    queryKey: ['LesPTA'],
    queryFn: GetAllPta,
  });

  const defaultValuePta = (unevaleur) => {
    if (LesYears.isSuccess)
      return LesYears.data.find((item) => item.value === unevaleur);
    else return null;
  };

  // eslint-disable-next-line no-unused-vars
  const RensDataUser = useQuery(
    ['RensDataUser', RensMainParamState],
    GetAllRens4User
  );

  useEffect(() => {
    async function fetchData() {
      setRowsDatas(await GetAllRens(RensMainParamState));
    }
    fetchData();
  }, [RensMainParamState.AnneePta]);

  const columns = [
    { title: 'Code', field: 'Indicateurs_id', editable: 'never' },
    {
      title: 'idRenseigner',
      field: 'idRenseigner',
      editable: 'never',
      hidden: true,
    },
    {
      title: 'Indicateur',
      field: 'Indicateur',
      editable: 'never',
      hidden: true,
    },
    { title: 'Indicateur', field: 'LibIndicateur', editable: 'never' },
    { title: 'Valeur', field: 'Valeur', type: 'numeric' },
    {
      title: 'Entiter_id',
      field: 'Entiter_id',
      editable: 'never',
      hidden: true,
    },
    { title: 'PTA_id', field: 'PTA_id', editable: 'never', hidden: true },
  ];
  // eslint-disable-next-line no-unused-vars
  const [selectedRow, setSelectedRow] = useState(null);
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      borderBottom: '1px dotted pink',
      color: state.isSelected ? 'red' : 'blue',
      padding: 20,
    }),
  };

  const options = {
    exportButton: true,
    pageSize: 65,
    pageSizeOptions: [65, 100, 150],
    actionsColumnIndex: -1,
    rowStyle: (rowData) => ({
      backgroundColor: selectedRow === rowData.tableData.id ? '#EEE' : '#FFF',
    }),
    headerStyle: {
      position: 'sticky',
      top: '0',
      backgroundColor: 'brown',
      fontWeight: 'bold',
      color: 'white',
    },
    maxBodyHeight: '500px',
  };

  return (
    <>
      <Head>
        <title>
          Caritas Bénin| Plateforme de Suivi-Evaluation de Redevabilité et
          d'apprentissage | Evaluation Plan Stratégique
        </title>
      </Head>
      <div className="bgc mt-3">
        <strong>Saisie des Indicateurs du PTA {RensMainParamState.AnneePta}</strong>
      </div>
      <div className="row">
        <div className=" col-md-2 col-xs-2">
          {' '}
          Veuillez choisir une Année
          <Select
            defaultValue={defaultValuePta(RensMainParamState.AnneePta)}
            menuPortalTarget={document.body}
            menuPosition="fixed"
            onChange={handleChange}
            options={LesYears?.data}
            theme={(theme) => ({
              ...theme,
              borderRadius: 0,
              colors: {
                ...theme.colors,
                text: '#3599B8',
                font: '#3599B8',
                primary25: '#3599B8',
                primary: '#3599B8',
                neutral80: 'black',
                color: 'black',
              },
            })}
            styles={customStyles}
          />
        </div>

        <div className="col-md-10">
          <TableIndicateurs
            SetDatas={setRowsDatas}
            datas={RowsDatas}
            columns={columns}
            options={options}
          />
        </div>
        {/* <div className='col-md-2'>
        <Button onClick={GenererRenseigner}>Générer</Button>
    </div>*/}
      </div>
      {/* Fin row 1*/}
    </>
  );
};

export default ProtectContent(MainComponent);
