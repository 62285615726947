import ServerRequest from '../helpers/ServerRequest';
import { ServerUrl } from '../constants/mainUrls';
export const GetAllTypeRapport= async ( ) => {
     const rep= await ServerRequest.post(`${ServerUrl}/api/upfiles/tyeperapports/`)
    return rep.data;
 };

 export const fileExist= async (fichier ) => {
     console.log('le fichier ',fichier)
     const rep= await ServerRequest.post(`${ServerUrl}/api/upfiles/exists/`,fichier)
     console.log('fileExist resultat ',rep)
   return rep.data;
 };

 export const RapportExist= async (fichier ) => {
     console.log('le fichier ',fichier)
     const rep= await ServerRequest.post(`${ServerUrl}/api/upfiles/rapport-existe/`,fichier)
     console.log('RapportExist resultat ',rep)
   return rep;
 };

 export const PtaIsSet= async (donnees ) => {
     console.log(' PtaIsSet : donnees =>',donnees)
     const rep= await ServerRequest.post(`${ServerUrl}/api/upfiles/rapport-pta-is-set/`,donnees)
     console.log('PtaIsSet resultat  => ',rep)
   return rep;
 };
 export const DeletePtaReportAnnuel= async (donnees ) => {
     console.log(' DeletePtaReportAnnuel : donnees =>',donnees)
     const rep= await ServerRequest.post(`${ServerUrl}/api/upfiles/rapport-pta-delete/`,donnees)
     console.log('DeletePtaReportAnnuel resultat  => ',rep)
   return rep;
 };


export const EnvoyerRapportSurPlateForme= async (datas) => {
  console.log('EnvoyerRapportSurPlateForme ------------------------------',datas)
  var formData = new FormData();
  const currentUser=sessionStorage.getItem('currentUser')
  const user=JSON.parse(currentUser)
  var descritionInfo=[]
  datas.ReportsFiles.map((item)=>{
      //console.log(item)
      formData.append('ReportsFiles',item.filename[0])
      formData.append('NomFichier', item.filename[0].name)
      formData.append('Chemin', 'rapport/annuel/')
      formData.append('Description',item.description)
      formData.append('Pta',item.pta)
      formData.append('UserId',user.id)
      formData.append('EntiterId',user.EntiterId)
      formData.append('rapportTypeId',1)
      
  })
  
  console.log("descritionInfo",descritionInfo)
  await ServerRequest.post(`${ServerUrl}/api/upfiles/save-File-rapport-annuel`, formData, 
     {
      headers: {
       "Content-Type": "multipart/form-data",
      // 'content-type': 'application/x-www-form-urlencoded;charset=utf-8',
      },
    }  )
};

export const GetRapports= async (datas) => {
  const rep= await ServerRequest.post(`${ServerUrl}/api/upfiles/get-rapports/`,datas)
  return rep;
 }
