import React from 'react'
import caritasLogo from '../../assets/images/caritas-logo.jpg'
import {Link} from "react-router-dom";
import OnLineBar from '../../containers/Dashboard/OnLineBar';
const HEADER_HEIGHT = 60;

const Topbar=({user})=>{
    
    return (
        <div className='flex items-center justify-between bg-[rgb(141,68,58)] text-aliceblue w-full'>
            
            <Link to="/evalps" style={{ textDecoration: "none" }}>
                <img className="logo" src={caritasLogo} style={{ height: HEADER_HEIGHT - 4 }} />
            </Link>
            <div className='text-lg font-bold text-white '>
                CARITAS Bénin |S.E.R.A 
            </div>
            <div >
                 <OnLineBar/>
            </div>
            <div className='text-white font-bold'>
                {user?.email} <br/>{user?.NomEntiter} 
            </div>
        </div>
    )
}
export default  Topbar