import EditIcon from '../../../components/Icones/EditIcon';
import { useState, useEffect } from 'react';
import { extractFieldsFromFormula, UpdateRens, useDeleteReinseignerMutation, useSaveReinseignerMutation } from '../../../DbApi/Renseigner.DbApi';
import { toast } from 'react-toastify';
import ValeursSousChamp from '../ValeursSousChamps';
import { useQueryClient } from '@tanstack/react-query';
import ShowIndicateurDetail from '../ShowIndicateurDetail';
import FormIndicateurV4 from './FormIndicateurV4';
import { useSelector } from 'react-redux';
import { FaTimes } from 'react-icons/fa';
import ConfirmationDialog from '../../../components/ConfirmationDialog';
import { BiMessageAltDetail } from "react-icons/bi";
import { FcViewDetails } from "react-icons/fc";
import ShowDialogDetailPanelMobileV4 from './ShowDialogDetailPanelMobileV4';
const ItemIndicateurMobileLayoutV4 = ({ idRenseigner, sources,indicateur }) => {
  const [Element, SetElement] = useState({});
  const [showFormIndicateur,setShowFormIndicateur]=useState(false)
  const [showValeursSousChamp,setShowValeursSousChamp]=useState(false);
  const [showDetail4Indic,setShowDetail4Indic]=useState(false);
  const [indicateurChoisi,setIndicateurChoisi]=useState(null);
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [detail, setDetail] = useState(false);
  const { Entiter, AnneePta, User_id } = useSelector((state) => state.RensMainParam);
  const {mutate:saveRenseigner}=useSaveReinseignerMutation()
  const {mutate:DeleteRens, isLoading: isDeleting}= useDeleteReinseignerMutation()
  
  
  
  const closeFormIndicateur=()=>{
    setShowFormIndicateur(false)
  }
  const handleDelete = () => {
    setIsConfirmOpen(true);
  };
  const confirmDelete = () => {
    let param={
      Entiter:Entiter,
      AnneePta:AnneePta,
      Indicateur:indicateur.Indicateurs_id,
      User_id:User_id 
    }
    DeleteRens({param},{
      onSuccess: () => {
        toast.success('Suppression effectuée avec succes')
        setIsConfirmOpen(false); // Fermer la boîte de dialogue de confirmation

      }});
    
  };
  const onSubmitFormIndicateur=(data)=>{
   data.AnneePta=AnneePta
   data.Entiter=Entiter
   data.User_id=User_id 
   let idIndicateur=indicateur.idIndicateur
   if (currentIndex < selectedItems.length - 1) {
    setCurrentIndex(currentIndex + 1); // Avancer à l’élément suivant
  } else {
    //closeFormIndicateur();
    toast.success('Maintenant on enregistre et on ferme la fenêtre ')
    // Affiche les résultats finaux ou autres actions après la soumission finale
  }
 /*  saveRenseigner({ idIndicateur, data },{
    onSuccess: () => {
      closeFormIndicateur();
      setIsConfirmOpen(false); // Fermer la boîte de dialogue de confirmation
      toast.success('Enregistrement effectué avec succes')
    },
    onError:(error)=> {
      toast.error(error.message)
    }
  }); */
  }
  const queryClient = useQueryClient();

  async function SaveAfterEdit(data) {
    var newElement = { ...Element, ...data };
    await UpdateRens(data);
    toast.success('Nouvelle valeur enregistrée avec success');
    SetElement(newElement);
  }
 const showSubFieldValeur=()=>{
  setShowValeursSousChamp(true)
 }
 const showIndicDetail=()=>{
  /* 1) rechercher l'indicateur dans la liste "LesIndicPsEnCours"
     2) mettre un hook sur la valeur retrouvée 
     3) mettre le bouton visible à true
  */
    //1):
    const ListeIndic= queryClient.getQueryData(['LesIndicPsEnCours'])
    let item=ListeIndic?.find((el=>  el.idIndicateur===indicateur.Indicateur.idIndicateur))
    //2)
    setIndicateurChoisi(item)
    //-3)
     setShowDetail4Indic(true)
 }
 const hideIndicDetail=()=>{
  setShowDetail4Indic(false)
 }
 const handleChangeIndicValue=()=>{
  const ListeIndic= queryClient.getQueryData(['LesIndicPsEnCours'])
  let item=ListeIndic?.find((el=>  el.idIndicateur===indicateur.Indicateur.idIndicateur))
  if (indicateur.Indicateur.Formule){
    const ListeIndic= queryClient.getQueryData(['LesIndicPsEnCours'])
    setSelectedItems([])  
    let fields=extractFieldsFromFormula(indicateur.Indicateur.Formule) 
    for (const  field of fields) {
      let toAdd=ListeIndic?.find((el=>  el.idIndicateur===field))
      console.log("toAdd",toAdd)
      setSelectedItems((prevItems) => [...prevItems, toAdd]);
      }
   } else {
    const ListeIndic= queryClient.getQueryData(['LesIndicPsEnCours'])
    let toAdd=ListeIndic?.find((el=>  el.idIndicateur===indicateur.Indicateur.idIndicateur))
    setSelectedItems([toAdd])
   }
  setIndicateurChoisi(item)
  setShowFormIndicateur(true)
 }

  return (
    <>
      <div className="bg-white rounded-md overflow-hidden shadow-xl shadow-red-500 ">
        {' '}
        {/* h-[27rem] shadow-[#50d71e] */}
     
        <span className="px-3 py-2 block">
          <span className="font-bold">Indicateur:</span>{' '}
          <span className="font-light">{indicateur.Indicateur.Libelle}</span>
        </span>
        <span className="px-3 pt-1 block">
          <span className="font-bold">Valeur:</span>{' '}
          <span className="font-light ">{indicateur.Valeur}</span>
          {indicateur.Indicateur.IndicateurSousChamp.length>0 &&
          <div>
            <button className='bg-gray-400 rounded-lg mx-2 p-1' onClick={showSubFieldValeur} >Valeurs des sous-champs</button>
          </div>
          }
        </span>
        
        <div className="flex flex-wrap justify-center items-center">
          <button
            className="inline-flex px-2 pt-1 mx-2 my-3 items-center text-indigo-100 bg-green-700 rounded-full hover:bg-green-900 "
            onClick={handleChangeIndicValue}
          >
            <EditIcon className="mr-3" />
            <span className="ms-2">Modifier</span>
          </button>
          <button
          onClick={handleDelete} 
          disabled={isDeleting}
          className="bg-red-500 text-white px-2 py-2 rounded flex items-center justify-center" 
        >  <FaTimes className='text-red-900'/>
           <span className='mx-1'>Supp</span>
        </button>
        
        <button
            className="inline-flex px-1 mx-1 my-3 items-center text-indigo-100 bg-blue-700 rounded-full hover:bg-blue-900 "
            onClick={() => setDetail(!detail)}
          >  
             <span><FcViewDetails className='mx-2'/></span>
             <span className='mr-2'>Détails</span>
          </button>


          <button
            className="inline-flex px-1 mx-1 my-3 items-center text-indigo-100 bg-blue-700 rounded-full hover:bg-blue-900 "
            onClick={showIndicDetail}
          >  
             <span><BiMessageAltDetail className='mx-2'/></span>
             <span className='mr-2'>Info</span>
          </button>

        </div>
      </div>
      <ShowDialogDetailPanelMobileV4
        idRenseigner={idRenseigner}
        sources={sources}
        showModal={detail}
        setShowModal={setDetail}
      />
          <ValeursSousChamp  
            isOpen={showValeursSousChamp}
            indicateur={indicateur}
            onClose={()=>setShowValeursSousChamp(false)}
          />
         <ShowIndicateurDetail
            isOpen={showDetail4Indic}
            indicateur={indicateurChoisi}
            onClose={hideIndicDetail}
         />
          <FormIndicateurV4
             items={selectedItems}
            step={currentIndex}
            isOpen={showFormIndicateur}
            onClose={closeFormIndicateur}
          />
          <ConfirmationDialog
          isOpen={isConfirmOpen}
          title="Confirmer la suppression"
          message={`Êtes-vous sûr de vouloir supprimer la valeur de `}
          Libelle={`" ${indicateur.Indicateur.Libelle}"`}
          onCancel={() => setIsConfirmOpen(false)}
          onConfirm={confirmDelete}
        />
    </>
  );
};

export default ItemIndicateurMobileLayoutV4;
