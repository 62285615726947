import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  IndicValues: [], // tableau d'IndicValues
};

const objetSlice = createSlice({
  name: 'RensIndicValues',
  initialState,
  reducers: {
    ajouterIndicValue: (state, action) => {
      state.IndicValues.push(action.payload);
    },
   
    supprimerIndicValue: (state, action) => {
      // Supprime un objet par sa clé (par exemple 'id')
      const key = action.payload;
      state.IndicValues = state.IndicValues.filter((indicValue) => indicValue.id !== key);
    },
    modifierIndicValue: (state, action) => {
      const { index, updatedObject } = action.payload;
      if (index >= 0 && index < state.IndicValues.length) {
        state.IndicValues[index] = { ...state.IndicValues[index], ...updatedObject };
      }
    },
    supprimerTousLesIndicValues: (state) => {
      state.IndicValues = [];
    },
    obtenirIndicValueParIndex: (state, action) => {
      const index = action.payload;
      if (index >= 0 && index < state.IndicValues.length) {
        return state.IndicValues[index];
      }
      return null;
    },
    obtenirIndicValueParCle: (state, action) => {
      // Recherche un objet par sa clé (par exemple 'id' dans cet exemple)
      const key = action.payload;
      return state.IndicValues.find((indicValue) => indicValue.id === key) || null;
    },
  },
});

// Export des actions
export const {
  ajouterIndicValue,
  supprimerIndicValue,
  modifierIndicValue,
  supprimerTousLesIndicValues,
  obtenirIndicValueParIndex,
  obtenirIndicValueParCle, // Ajouté pour recherche par clé
} = objetSlice.actions;

// Export du reducer pour l'ajouter au store
export default objetSlice.reducer;
