/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useForm, useFieldArray } from 'react-hook-form';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import ViewRapportUploaded from './ViewRapportUploaded';
//import 'bootstrap/dist/css/bootstrap.css';
import {
  RapportExist,
  EnvoyerRapportSurPlateForme,
  PtaIsSet,
} from '../../DbApi/Files.DbApi';
import { GetAllPta } from '../../DbApi/Pta.DbApi';
import { GetRapports } from '../../DbApi/Files.DbApi';
import { DeletePtaReportAnnuel } from '../../DbApi/Files.DbApi';
import Confirm from '../../helpers/ConfirmService/Confirm';

const UploadRepportsForm = ({ param }) => {
  const { register, control, handleSubmit, formState } = useForm();
  const { errors } = formState;
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'ReportsFiles',
  });
  const user = JSON.parse(sessionStorage.getItem('currentUser'));
  const [nbeRefresh, SetnbeRefresh] = useState(0);
  const [ListePtas, setListePtas] = useState([]);

  //   const ddownloadTheFile = async (file) => {
  //<a href='/somefile.txt' download>Click to download</a>
  //<Link to="/files/myfile.pdf" target="_blank" download>Download</Link>
  //Where /files/myfile.pdf is inside your public folder.
  // npm i file-saver
  /* const saveFile = () => {
            saveAs(
              "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
              "example.pdf"
            );
          };
          return (
            <div>
              <button onClick={saveFile}>download</button>
            </div>
          ); */
  // };

  const rapportExist = async (datas) => {
    const { data } = await RapportExist(datas);
    console.log('data nbre', data[0].nbre);
    return data[0].nbre;
  };
  const PtaIsUnique = async (datas) => {
    const { data } = await PtaIsSet(datas);
    console.log('data nbre', data[0].nbre);
    return data[0].nbre;
  };
  const deleteRapport = async (id) => {
    const result = await Confirm.show({
      title: 'ATTENTION Veillez confirmer',
      message: 'Voulez-vous vraiment supprimer cet élément ?',
    });
    console.log('result', result);
    if (result) {
      DeletePtaReportAnnuel({ id: id });
      SetnbeRefresh(nbeRefresh + 1);
    }
  };
  const onSubmit = async (data) => {
    ////////////////////
    console.log(')=====> onSubmit data ', data);
    EnvoyerRapportSurPlateForme(data);
    SetnbeRefresh(nbeRefresh + 1);
  }; ////////////////////////////////////////////////////
  const mainTitre = {
    color: 'brown',
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'white',
    padding: '10px',
    fontFamily: 'Arial',
  };
  const [lesRapports, setLesRapports] = useState(null);
  useEffect(() => {
    async function fetchData() {
      const lesYears = await GetAllPta();
      setListePtas(lesYears);
      const Retour = await GetRapports({
        UserId: user.id,
        EntiterId: user.EntiterId,
        rapportTypeId: 1,
      });
      console.log(
        'Résultat de la requette pour récupérer les rapports déjà disponibles sur le server : ',
        Retour.data
      );
      setLesRapports(Retour.data);
    }
    fetchData();
  }, [nbeRefresh]);
  return (
    <>
      <div className="mx-2">
        <h3 style={mainTitre}>
          Mise en ligne des rapports annuels (fichiers word ou Pdf)
        </h3>
        <div className="row d-flex flex-row py-5">
          <div className="col-sm-6 col-md-6">
            {lesRapports && (
              <ViewRapportUploaded
                lesRapports={lesRapports}
                deleteRapport={deleteRapport}
              />
            )}
          </div>
          <div className="col-sm-6 col-md-6">
            <p className="text-light bg-success text-center">
              {' '}
              Veuillez sélectionner les documents à envoyer sur la plateform
            </p>
            <form onSubmit={handleSubmit(onSubmit)}>
              {fields.map((item, i) => (
                <div className="row d-flex flex-row" key={i}>
                  <div className="list-group-item">
                    <h5 className="card-title">Fichier {i + 1}</h5>
                    <div className="form-row">
                      <div className="form-group col-12 mb-3">
                        <label>Fichier</label>
                        <input
                          name={`ReportsFiles[${i}]filename`}
                          {...register(`ReportsFiles.${i}.filename`, {
                            required: 'Veuillez choisir un fichier',
                            validate: async (value) => {
                              console.log('value', value[0].name);
                              var un = {
                                Pta: 'rapport/annuel/' + value[0].name,
                              };
                              let ex = await rapportExist(un);
                              console.log('ex ?', ex);
                              console.log('ex!=1 ?', ex !== 1);
                              console.log('ex!==1 ?', ex !== 1);
                              return ex !== 1;
                            },
                          })}
                          type="file"
                          accept=".doc,.docx,.pdf,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                          onChange={(e) => {
                            console.log(e.target.files);
                          }}
                        />
                        <div className="invalid-feedback">
                          {errors.ReportsFiles?.[i]?.filename?.message}
                        </div>
                        {errors.ReportsFiles?.[i]?.filename && (
                          <small className="text-danger">
                            {errors.ReportsFiles?.[i]?.filename.message}
                          </small>
                        )}
                        {errors.ReportsFiles?.[i]?.filename &&
                          errors.ReportsFiles?.[i]?.filename.type ===
                            'validate' && (
                            <div className="text-danger">
                              Ce fichier a déjà été choisi
                            </div>
                          )}
                      </div>
                      <div className="form-group col-12 mb-3">
                        <label>Description</label>
                        <input
                          name={`ReportsFiles[${i}]description`}
                          {...register(`ReportsFiles.${i}.description`, {
                            required:
                              'Veuillez saisir une description pour ce fichier',
                          })}
                          type="text"
                          className={`form-control ${
                            errors.ReportsFiles?.[i]?.description
                              ? 'is-invalid'
                              : ''
                          }`}
                        />
                        <div className="invalid-feedback">
                          {errors.ReportsFiles?.[i]?.description?.message}
                        </div>
                      </div>
                      <div className="form-group col-12 mb-3">
                        <TextField
                          select
                          fullWidth
                          defaultValue=""
                          label="Choisir un PTA"
                          inputProps={register(`ReportsFiles.${i}.pta`, {
                            required: 'Vous devez préciser le PTA concerné',
                            validate: async (value) => {
                              console.log('value', value);
                              var un = {
                                Pta: value,
                                rapportTypeId: 1,
                                EntiterId: user.EntiterId,
                              };
                              let ex = await PtaIsUnique(un);
                              /* console.log (" PTA ex ?",ex)
                                                    console.log (" PTA ex!=1 ?",ex!=1)
                                                    console.log ("PTA ex ===0 ?",ex===0) 
                                                    */
                              return ex === 0;
                            },
                          })}
                          error={errors.currency}
                          /* helperText={errors.ReportsFiles?.[i]?.pta?.message} */
                          className={`form-control ${
                            errors.ReportsFiles?.[i]?.pta ? 'is-invalid' : ''
                          }`}
                        >
                          {ListePtas.map((pta) => (
                            <MenuItem key={pta.value} value={pta.value}>
                              {pta.label}
                            </MenuItem>
                          ))}
                        </TextField>

                        <div>
                          {errors.ReportsFiles?.[i]?.pta && (
                            <small className="text-danger">
                              {errors.ReportsFiles?.[i]?.pta.message}
                            </small>
                          )}
                          <div className="invalid-feedback">
                            {errors.ReportsFiles?.[i]?.pta?.message}
                          </div>

                          {errors.ReportsFiles?.[i]?.pta &&
                            errors.ReportsFiles?.[i]?.pta.type ===
                              'validate' && (
                              <div className="text-danger">
                                Le rapport de ce Pta est déjà sur la plateform
                              </div>
                            )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <button
                    className="bg-red-600 text-white hover:bg-red-900"
                    onClick={() => remove(i)}
                  >
                    Supprimer ce fichier
                  </button>
                </div>
              ))}
              <div className="row ">
                <button
                  className="bg-blue-700 text-yellow-100"
                  type="button"
                  onClick={() => append()}
                >
                  Ajouter un Fichier
                </button>
              </div>

              <div className="row ">
                <input
                  className="bg-green-800 text-white hover:bg-green-950 hover:text-white"
                  type="submit"
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default UploadRepportsForm;
