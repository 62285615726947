import React from 'react';
import { Button } from '@mui/material';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';

const ShowComponent = (props) => {
  const {title,children,open,setOpen}=props
  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="confirm-dialog"
    >
      <DialogTitle id="confirm-dialog">{title}</DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        
        <Button
          variant="contained"
          onClick={() => { setOpen(false);  }}
          color="primary"
        >
          Fermer
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default ShowComponent;
