import ServerRequest from '../helpers/ServerRequest';
import { ServerUrl } from '../constants/mainUrls';

export const UserRoleGetUserbyEmail = async (email) => {
  const Param = { Email: email };
  await ServerRequest.post(`${ServerUrl}/api/usroles/usbymail`, Param);
};

export const UserRoleSeConnecter = async (data) => {
  //await ServerRequest.post(`${ServerUrl}/api/users/upwd/`,data)
  //console.log('/api/usroles/connect/ data: ', data);
  const rep = await ServerRequest.post(
    `${ServerUrl}/api/usroles/connect/`,
    data
  );
  //console.log("/api/usroles/connect/ : ",rep.data)
  return rep.data;
};
export const isUserAdmin = async (email) => {};
