/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';
import './Evalpta.css';
import TemplatePages from '../Dashboard/TemplatePages';
import MainComponent from './MainComponent';
import { IndicateursMobileLayoutV4 } from './V4/IndicateursMobileLayoutV4';
import { useState } from 'react';
import { useQueryClient, useQuery } from '@tanstack/react-query';
import { GetAllRens4User } from '../../DbApi/Renseigner.DbApi';
import { useNavigate } from 'react-router-dom';
import { useSelector,useDispatch } from 'react-redux';
import { FormProviderV4 } from './V4/FormContextV4';
import { setEntiter, setUserId } from '../../store/RenseignerMainParam';
const Evalpta = () => {
  const naviguer = useNavigate();
  const queryClient = useQueryClient();
  const { Entiter, AnneePta, User_id } = useSelector((state) => state.RensMainParam);
  const dispatch = useDispatch();
  let currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
  let annee= (new Date()).getFullYear().toString()
  if (currentUser) {
    dispatch(setUserId(currentUser.id));
    dispatch(setEntiter(currentUser.EntiterId));
  }
  const [isTimeout, setIsTimeout] = useState(false);
  if (isTimeout) {
    sessionStorage.removeItem('currentUser');
    naviguer('/');
  }

  useEffect(() => {
    /*   const timer = new IdleTimer({
      timeout: 900, //expire after N seconds
      onTimeout: () => {
        setIsTimeout(true);
      },
      onExpired: () => {
        // do something if expired on load
        setIsTimeout(true);
      },
    }); */
    /*    return () => {
      timer.cleanUp();
    }; */
  }, []);


  queryClient.prefetchQuery({
    queryKey: ['RensDataUser',AnneePta],
    queryFn: GetAllRens4User,
  });

  const [showTableForme, SetshowTableForme] = useState(false);

  const RenderComponent = () => {
    return showTableForme ? (
      <TemplatePages Fils={<MainComponent />} />
    ) : (
      <TemplatePages Fils={<IndicateursMobileLayoutV4 />} />
    );
  };
  return (
    <div>
     {/*  <div className="inline ">
        <button
          onClick={() => SetshowTableForme(!showTableForme)}
          className="bg-green-300 text+while font-bold flex flex-row"
        >
          {' '}
          <span className="inline ms-3 mx-2">Mode d'affichage:</span>
          {showTableForme ? (
            <span className="inline">
              <IconToggleSwitchOutline className="inline mr-1" /> Tableau
            </span>
          ) : (
            <span className="inline">
              {' '}
              <IconToggleSwitchOffOutline className="inline mr-1" /> Formulaire{' '}
            </span>
          )}
        </button>
      </div> */}
      <FormProviderV4>
      <RenderComponent />
      </FormProviderV4>
      
    </div>
  );
};

export default Evalpta;
