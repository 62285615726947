import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { fetchAllIndicateursPrisma } from '../../../DbApi/indicateurs.DbApi';
import { FaSearch,FaTimes ,FaListUl} from 'react-icons/fa';
import { useNavigate } from 'react-router-dom'
import NewSidebar from '../../../components/NewSideBar/NewSidebar';
import Topbar from '../../../components/TopBar/Topbar';
import '../../Dashboard/TableauxDeBord.scss'
const Liste_Indicateurs = () => {
  const { data: LesIndicateurs, isSuccess: ListIndicOK } = useQuery({
    queryKey: ['LesIndicPsEnCours'],
    queryFn: fetchAllIndicateursPrisma,
  });
  const naviguer=useNavigate()
  const [searchTerm, setSearchTerm] = useState('');

  const filteredIndicateurs = LesIndicateurs?.filter((indicateur) =>
    indicateur.Libelle.toLowerCase().includes(searchTerm.toLowerCase()) ||
    indicateur.Description.toLowerCase().includes(searchTerm.toLowerCase())
  );
 
  return (
    <div className="p-4 bg-gray-100 w-full mx-auto">
      {/* En-tête contenant la recherche, le bouton fermer et le nombre d'éléments */}
      <div className="flex flex-col sm:flex-row items-center justify-between mb-4 space-y-4 sm:space-y-0">
        <div className="flex items-center space-x-2">
          <FaSearch className="text-gray-500" />
          <label className="text-gray-700 font-medium">Rechercher</label>
          <input
            type="text"
            placeholder="Libellé ou Description..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-red-500"
          />
        </div>

        {/* Nombre d'éléments affichés */}
        <div className="flex items-center space-x-2 text-gray-700">
          <FaListUl className="text-red-500" />
          <span>{filteredIndicateurs?.length || 0}  {filteredIndicateurs?.length > 1 ?'éléments':'élément'} </span>
        </div>

        <button
          type="button"
          onClick={() => naviguer("/bord")}
          className="bg-red-500 text-white px-4 py-2 rounded-md hover:bg-red-600 flex items-center space-x-2"
        >
          <FaTimes />
          <span>Fermer</span>
        </button>
      </div>

      {/* Conteneur défilant pour la liste des indicateurs */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 max-h-96 overflow-y-scroll border-t border-gray-300 pt-4">
        {ListIndicOK && filteredIndicateurs?.map((indicateur, index) => (
          <div
            key={index}
            className="p-4 border border-red-500 rounded-lg shadow-md bg-white"
          >
            <p className="font-semibold text-lg text-red-700">
              <strong>Libellé :</strong> {indicateur.Libelle}
            </p>
            <p><strong>Description :</strong> {indicateur.Description}</p>

            <p>
              <strong>A des sous-Champ ? :</strong>{' '}
              {indicateur.IndicateurSousChamp?.length > 0 ? 'Oui' : 'Non'}
            </p>

            <p>
              <strong>Mode :</strong>{' '}
              {indicateur.formule
                ? 'Automatique'
                : indicateur.IndicateurSousChamp?.length > 0
                ? 'Somme des Sous Champs'
                : 'Saisie'}
            </p>

            <p><strong>Résultat :</strong> {indicateur.Resultat.Libelle}</p>

            <p><strong>Objectif :</strong> {indicateur.Resultat.Objectif.Libelle}</p>

            <p><strong>O.S :</strong> {indicateur.Resultat.Objectif.orientation.Libelle}</p>
          </div>
        ))}
        {ListIndicOK && filteredIndicateurs?.length === 0 && (
          <p className="text-center text-gray-500">Aucun indicateur ne correspond à votre recherche.</p>
        )}
      </div>
    </div>
  );
};
const ListeIndicateurs = () => {
  let currentUser = sessionStorage.getItem('currentUser');
  const user = JSON.parse(currentUser);
    return (
        <>
            <div className="flex flex-row">
                <Topbar user={user} />
            </div>
            <div className="flex flex-row bg-[rgb(161,10,10)] ">
                <NewSidebar />
                <div className="min-h-screen flex-[3] bg-white">
                    <Liste_Indicateurs />
                </div>
            </div>
        </>
    );
  };
export default ListeIndicateurs;
