import { Routes, Route } from 'react-router-dom';
import Home from './containers/Home';
import LogInForm from './containers/LogInForm';
import Logout from './containers/Logout';
//import AnnuelReportForm from './containers/AnnuelReport/AnnuelReportForm';
import ShowUploadRepportsForm from './containers/AnnuelReport/ShowUploadRepportsForm';
//import Evalpta from './containers/EvalPtas/Evalpta';
import Evalpta from './containers/Evalpta/Evalpta';
import SetSourcesVerifications from './containers/Evalpta/SetSourcesVerifications';
import ShowCreateUser from './containers/CreateUser/ShowCreateUser';
import { OnlineStatusProvider } from './helpers/useOnlineStatus';
import Notifier from './store/Alertes/Notifier';
import AlertMessage from './containers/AlertMessage';
import ShowChangePassWord from './containers/ChangePassWord/ShowChangePassWord';
import AdminPta from './containers/AdminPta/AdminPta';
import Principal from './containers/Evalpta/SeqForms/Principal';
import ChangePassWordV2 from './containers/ChangePassWord/ChangePassWordV2';
import CreateUserV2 from './containers/CreateUser/CreateUserV2';
import ListeIndicateurs from './containers/Evalpta/V4/ListeIndicateurs';
function MainApp() {
  Notifier();
  return (
    <div>
      
      <OnlineStatusProvider>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<LogInForm />} />
          <Route path="/chpssw" element={<ShowChangePassWord />} />
          <Route path="/chpsswV2" element={<ChangePassWordV2 />} />
          <Route path="/bord" element={<Evalpta />} />
          <Route path="/logout" element={<Logout />} />
          <Route path="/rpword" element={<ShowUploadRepportsForm />} />
          <Route path="/evalps" element={<Evalpta />} />
          <Route path="/setsrc" element={<SetSourcesVerifications />} />
          <Route path="/crus" element={<ShowCreateUser />} />
          <Route path="/crusV2" element={<CreateUserV2 />} />
          <Route path="/view" element={<AdminPta />} />
          <Route path="/test" element={<Principal />} />
          <Route path="/listindic" element={<ListeIndicateurs />} />
        </Routes>
      </OnlineStatusProvider>
      <AlertMessage />
    </div>
  );
}

export default MainApp;
