
import React, { createContext, useContext, useState } from 'react';
const FormContextV4 = createContext();
export const FormProviderV4 = ({ children }) => {
  const [formData, setFormData] = useState({});
  const [initialDataLoaded, setInitialDataLoaded] = useState({});

  const updateFormData = (key, data) => {
    setFormData((prevData) => ({
      ...prevData,
      [key]: { ...prevData[key], ...data },
    }));
  };
  const setInitialDataForStep = async (key, data = {}) => {
    try {
      setFormData((prevData) => ({
        ...prevData,
        [key]: data,
      }));
      
      setInitialDataLoaded((prevLoaded) => ({
        ...prevLoaded,
        [key]: true,
      }));
    } catch (error) {
      console.error(`Erreur de chargement des données initiales pour l'étape ${key}:`, error);
    }
  };
  const resetFormData = () => {
    setFormData({});
    setInitialDataLoaded({});
  };
  return (
    <FormContextV4.Provider value={{ formData, updateFormData, setInitialDataForStep, initialDataLoaded ,resetFormData}}>
      {children}
    </FormContextV4.Provider>
  );
};

export const useFormDataV4 = () => useContext(FormContextV4);
