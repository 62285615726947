import React ,{useState,useEffect} from 'react'
import { useForm, Controller } from 'react-hook-form';
import { TextField,InputAdornment, IconButton,Button,Grid } from '@mui/material';
import { UserExists, insertUser} from '../../DbApi/Users.DbApi';
import { useNavigate } from "react-router-dom";
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import PersonIcon from '@mui/icons-material/Person';
import VisibilityIcon from '@mui/icons-material/Visibility';
import LoginIcon from '@mui/icons-material/Login';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import HomeIcon from '@mui/icons-material/Home';
import Select from 'react-select';
import {Select as MuiSelect } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { getEntiterForSelectBox } from '../../DbApi/entiter.DbApi';
import { SeConnecter } from '../../DbApi/Users.DbApi';
import { UpdatePassWord } from '../../DbApi/Users.DbApi';
import './ChangePassWord.css'

const ChangePassWord = () => {
    const naviguer=useNavigate()
    const [message,setMessage]=useState('')
    const [resultat,setResultat]=useState(false)
    const [selectedEntiter,setSelectedEntiter]=useState('')
    const [CurrentUser,setCurrentUser]=useState(undefined)
    const { handleSubmit,reset, formState: { errors },control,   } = useForm({mode:'onTouched'}); 
    
    
    const onSubmit=async data => {
        data.email=CurrentUser.email
        console.log(' on Submit avec datas ! ',data)
        const re=await UpdatePassWord(data)
        if (re.status===200){
            setMessage("Modification effectuée avec succes")
            setResultat(true)

        }
        console.log("Le résultat de la soumission=" , re)
        
       

    }
    const [showPassword, setShowPassword] = useState(false);
    const handleClick = () => {
        setShowPassword(prev => !prev);
      }
      const handleChangeEntiter = (e) => {
        console.log("selectedOption",e.target.value)
        setSelectedEntiter(e.target.value);
      }   
     
    useEffect(() => {
        let currentUser=sessionStorage.getItem('currentUser')
        setCurrentUser(JSON.parse(currentUser))
    }, [])
    
      return (
        <div className='container d-flex flex-column justify-content-center align-items-center'>
            <small className={ resultat ? "text-success":"text-danger" }>{message}</small>

        <form onSubmit={handleSubmit(onSubmit)}> 
            <div className='d-flex flex-column justify-content-center align-items-center'>
                <div>
                     <h3 className='border-b text-center mb-3 pb-3 font-bold' >Modification du mot de passe</h3>
                </div>
                
                <div className='col-12 mt-2 mb-3 d-flex flex-column '>
                <div className='margebas' >
                <Controller
                    name="currentPassword"
                    control={control}
                    defaultValue={""}
                    rules={{ 
                        required: 'Ce champ doit avoir une valeur',
                        validate:async(value)=>{
                            console.log (" Validation ",CurrentUser.email)
                            const re= await SeConnecter({email:CurrentUser.email,password:value})
                            return re.valid
                        }
                    }}
                    render={({
                        field: { onChange, onBlur, value, name, ref },
                        fieldState: { isTouched, isDirty, error },
                        formState,
                    }) =>
                    (
                        <TextField
                        label={ <span style={{ color: "#0D2801" }} ><VpnKeyIcon/>Mot de passe actuel*</span>}
                        onChange={onChange} value={value}
                        type={"password"}
                        />
                    )}
                />
                </div >
                <div >  
                    {errors.currentPassword && <small className="text-danger">{errors.currentPassword.message}</small>} 
                    {errors.currentPassword && errors.currentPassword.type === "validate" && (
                            <div className="text-danger">Mot de passe erroné</div>
                        )
                        }
                </div>
                </div> 
               
               <div className='col-12 mt-2 mb-3 d-flex flex-column '>
                <div className='margebas' >
                <Controller
                    name="password"
                    control={control}
                    defaultValue={""}
                    rules={{ 
                        required: 'Ce champ doit avoir une valeur',
                        /* validate:(value)=>{
                            if (UserInfo.current.exist )
                            {
                                return (UserInfo.current.item[0].Userpwdl===value)
                            }
                        } */
                    }}
                    render={({
                        field: { onChange, onBlur, value, name, ref },
                        fieldState: { isTouched, isDirty, error },
                        formState,
                    }) =>
                    (
                        <TextField
                        label={ <span style={{ color: "#0D2801" }} ><VpnKeyIcon/>Nouveau Mot de passe*</span>}
                        onChange={onChange} value={value}
                        type={showPassword ? 'text' : "password"}
                        InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                 <IconButton
                                   onClick={handleClick}
                                   edge="end"
                                 >
                                   {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                 </IconButton>
                               </InputAdornment>
                            ) 
                          }}
                        />
                    )}
                />
                </div >
                <div >  
                    {errors.password && <small className="text-danger">{errors.password.message}</small>} 
                    {errors.password && errors.password.type === "validate" && (
                            <div className="text-danger">Mot de passe non valid</div>
                        )
                        }
                </div>
                </div>         
                {!resultat && <Grid container direction="row"  item xs={12} justifyContent="center" alignItems="center">
                 
                    <Button size='small' variant="contained" color="error" startIcon={<CancelOutlinedIcon/>} onClick={() =>
                        {
                            reset({currentPassword:'',password:''})
                            naviguer("/")
                        }
                        }>  Abandonner 
                    </Button>
                    
                    <Button size='small' variant="contained" color="primary" type="submit" startIcon={<LoginIcon/>}> Valider</Button>
                    
                </Grid>}
                {resultat &&<Grid container direction="row"  item xs={12} justifyContent="center" alignItems="center">
                 
                    <Button size='small' variant="contained" color="success" startIcon={<HomeIcon/>} onClick={() =>
                        {
                            
                            naviguer("/bord")
                        }
                        }>  Page d'accueil
                    </Button>
                        
                    
                </Grid>}
                
            </div>   
          </form>
        </div>
    )
}
export default (ChangePassWord)