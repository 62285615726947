import React, { useState, useEffect } from 'react';
import './DetailPanel.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { getDateInFormat_yyyy_mm_dd_hh_mm_ss_SSS } from '../../utils/general';
import Select from 'react-select';
import {
  ApplyCommentaire,
  getSourcesForIdRenseigner,
  SaveOnlySources,
} from '../../DbApi/sources.DBApi';
import { isStringEmpty } from '../../utils/general';
import { GetRens } from '../../DbApi/Renseigner.DbApi';

const DetailPanel = ({ idRenseigner, sources }) => {
  const [selectedOption, SetSelectedOption] = useState([]);
  const [firstOption, SetfirstOption] = useState([]);
  const [commentaire, setCommentaire] = useState('');
  const [commentaireOnFocus, setCommentaireOnFocus] = useState('');
  const [rowData, setRowData] = useState({});

  const handleChangeCommentaire = (event) => {
    setCommentaire(event.target.value);
  };

  const handleChange = async (TheselectedOption) => {
    SetSelectedOption(TheselectedOption);
    await SaveOnlySources(TheselectedOption, rowData.idRenseigner);
    SetfirstOption(TheselectedOption);

    console.log('**************    selectedOption', selectedOption);
    console.log('**************    idRenseigner', rowData.idRenseigner)
  };

  useEffect(() => {
    async function fetchData() {
      GetRens({ idRenseigner: idRenseigner }).then((detail) => {
        setRowData(detail);
        setCommentaire(detail.Commentaire);
      });

      const resultat = await getSourcesForIdRenseigner({
        idRenseigner: idRenseigner,
      });
      var tableau2 = [];
      // eslint-disable-next-line array-callback-return
      resultat.map((item) => {
        let UneSource = sources.find((sc) => {
          return sc.value === item.Source_id;
        });
        tableau2.push(UneSource);
      });
      SetSelectedOption(tableau2);
      SetfirstOption(tableau2);
      //USStates.find(({ value }) => value === props.state)
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idRenseigner, sources]);

  const SaveCommentaire = async () => {
    await ApplyCommentaire(rowData.idRenseigner, commentaire);
    if (isStringEmpty(commentaire)) {
    } else {
    }
  };

  const OnFocusHandeler = () => {
    setCommentaireOnFocus(commentaire);
  };
  const OnBludHandler = () => {
    // toast.info(`OldValue ; ${commentaireOnFocus} - NewValue : ${commentaire}`);
    if (commentaireOnFocus !== commentaire) SaveCommentaire();
  };
  //idRenseigner=2522,2549
  return (
    <div className="d-flex flex-column justify-content-center align-items-center detailmain">
      <div className="col-8 mt-2 mb-1">
        Résultat : {rowData.CodeResultat} -- {rowData.LibResultat}
      </div>
      <div className="col-8 mt-3 mb-1 textColorBrown">
        Indicateur : {rowData.Indicateurs_id} -- {rowData.LibIndicateur}
      </div>

      <div className="col-8 mt-3 mb-3">
        <strong>
          Veuillez choisir une ou plusieurs sources de vérification
        </strong>
      </div>
      <div className="col-8">
        <Select
          value={selectedOption}
          isMulti={true}
          name="Sources"
          options={sources}
          className="basic-multi-select"
          classNamePrefix="select"
          onChange={handleChange}
          // defaultValue={[colourOptions[2], colourOptions[3]]}
        />
      </div>
      <div className="flex flex-col justify-center items-center">
        <label>
          <strong>Commentaire/Observation (facultatif)</strong>
        </label>
        <textarea
          value={commentaire}
          onChange={handleChangeCommentaire}
          rows={10}
          cols={50}
          onFocus={OnFocusHandeler}
          onBlur={OnBludHandler}
        />
      </div>
    </div>
  );
};

export default DetailPanel;
