import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import { globalDialogReducer } from './GlobalDialogSlice';
import RensMainParamReducer from "./RenseignerMainParam"
import indicValues from './RenseignerIndicateurValues'
export const rootReducer = combineReducers({
 // error: errorSlice.reducer,
  globalDialogStatus:globalDialogReducer,
  RensMainParam:RensMainParamReducer,
  RensIndicValues:indicValues

  //other reducers here
})
export const store = configureStore({
  reducer: rootReducer,
  /* devTools: process.env.NODE_ENV !== 'production', */
  devTools:true,
})