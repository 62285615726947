import React from 'react'
import { useEffect } from 'react'
import { useNavigate } from "react-router-dom";

const Logout = ()=> {
  const naviguer=useNavigate()
  
  useEffect(() => {
      sessionStorage.removeItem("currentUser")
      naviguer("/")
    
   }, [])
    return (
      <div></div>
    )

}

export default Logout
