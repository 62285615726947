import React from 'react';
import { useNavigate } from 'react-router-dom';
import './AdminPta.css';
import TemplatePages from '../Dashboard/TemplatePages';
import MainComponent from './MainComponent';
import { toast } from 'react-toastify';

const AdminPta = () => {
  const naviguer = useNavigate();
  let tempo = sessionStorage.getItem('currentUser');
  const currentUser = JSON.parse(tempo);
  console.log('AdminPta =>currentUser=', currentUser);
  if (!currentUser) {
    let message = 'Veuillez vous connecter';
    toast.error(message);

    naviguer('/login');
  } else {
    let index = currentUser.roles.findIndex(
      (item) => item.LibRole === 'Administrateur'
    );
    if (index === -1) {
      let message =
        "Vous n'avez pas les droits d'accès à cette fonctionnalité de la plateforme\n\rVous êtes automatiquement redirigés vers l'écran de saisie des indicateurs";

      toast.error(message);
      naviguer('/bord');
    }
  }

  return <TemplatePages Fils={<MainComponent />} />;
};
export default AdminPta;
