/* eslint-disable no-unused-vars */
import ServerRequest from '../helpers/ServerRequest';

import { ServerUrl } from '../constants/mainUrls';
import { ShowGlobalDialog } from '../store/GlobalDialogSlice';
import { store } from '../store/rootReducer';
import { toast } from 'react-toastify';
import { getDateInFormat_yyyy_mm_dd_hh_mm_ss_SSS } from '../utils/general';

export const GetAllSources = async () => {
  const { data } = await ServerRequest.post(`${ServerUrl}/api/src/all/`);
  // console.log("/api/src/all/ ",data)
  return data;
};
export const SaveOnlySources = async (TheselectedOption, idRenseigner) => {}

export const SaveSourcesToDB = async (datas) => {
  console.log(
    getDateInFormat_yyyy_mm_dd_hh_mm_ss_SSS() +
      ' Sources DBApi => SaveSourcesToDB reçues datas',
    datas
  );

  try {
   const {data}=await ServerRequest.post(`${ServerUrl}/api/src/savesrc2db/`,{datas})
    console.log(
      getDateInFormat_yyyy_mm_dd_hh_mm_ss_SSS() +
        ' Sources DBApi => SaveSourcesToDB Resultat après exécution du ServerRequest.post  => ',
    );
    toast.success('Sources enregistrées avec succes!');
    return data;
  } catch (error){
    console.error(
      getDateInFormat_yyyy_mm_dd_hh_mm_ss_SSS() +
        ' Sources DBApi => ApplySources ERREUR après exécution du ServerRequest.post  => ',
      error
    );
    toast.error(
      getDateInFormat_yyyy_mm_dd_hh_mm_ss_SSS() +
        " Erreor pendant l'enregistrement " +
        JSON.stringify(error)
    );
    throw error;
  }
};
export const ApplyCommentaire = async (id, commentaire) => {
  let datas = {
    id: id,
    commentaire: commentaire,
  };
  const { data } = await ServerRequest.post(
    `${ServerUrl}/api/src/comm/`,
    datas
  );

  toast.success(' Commentaire enregistré avec succes');
};
export const getSourcesForIdRenseigner = async (datas) => {
  const { data } = await ServerRequest.post(
    `${ServerUrl}/api/src/gets/`,
    datas
  );
  return data;
};
export const resetSources = async (id) => {};
