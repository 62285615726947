import { useForm } from 'react-hook-form';

const initialOptions = [
  { id: '1', name: 'Option 1' },
  { id: '2', name: 'Option 2' },
  { id: '3', name: 'Option 3 avec associations' },
];

const Selection = ({ onSubmit }) => {
  const { register, handleSubmit } = useForm();

  const handleSelection = (data) => {
    const selectedItemId = data.selectedItem;
    const selectedItem = initialOptions.find((item) => item.id === selectedItemId);
    let items;

    if (selectedItem && selectedItemId === '3') {
      items = [
        selectedItem, // Ajoute l'élément principal sélectionné
        { id: '3-1', name: 'Élément associé 1' },
        { id: '3-2', name: 'Élément associé 2' },
      ];
    } else if (selectedItem) {
      items = [selectedItem];
    }

    if (items) {
      onSubmit(items);
    }
  };

  return (
    <form onSubmit={handleSubmit(handleSelection)}>
      <label htmlFor="selectedItem">Choisissez un élément :</label>
      <select {...register('selectedItem')} id="selectedItem">
        {initialOptions.map((option) => (
          <option key={option.id} value={option.id}>
            {option.name}
          </option>
        ))}
      </select>
      <button type="submit">Valider</button>
    </form>
  );
};

export default Selection;
