import NewSidebar from '../../components/NewSideBar/NewSidebar';
import Topbar from '../../components/TopBar/Topbar';
import ProtectContent from '../../helpers/ProtectContent';
import './TableauxDeBord.scss';
import IdleTimer from '../../helpers/IdleTimer';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
const TemplatePages = ({ Fils }) => {
  const naviguer = useNavigate();
  /*   const location = useLocation();
     user=location.state
    console.log("user = ",location.user) */
  let currentUser = sessionStorage.getItem('currentUser');
  const user = JSON.parse(currentUser);
  /*   const [isTimeout, setIsTimeout] = useState(false);
  if (isTimeout) naviguer('/logout');

  useEffect(() => {
    const timer = new IdleTimer({
      timeout: 60, //expire after 10 seconds
      onTimeout: () => {
        setIsTimeout(true);
      },
      onExpired: () => {
        // do something if expired on load
        setIsTimeout(true);
      },
    });

    return () => {
      timer.cleanUp();
    };
  }, []); */

  return (
    <>
      <div className="row">
        <Topbar user={user} />
      </div>
      <div className="board">
        <NewSidebar />
        <div className="boardContainer toutHauteur">{Fils}</div>
      </div>
    </>
  );
};

export default ProtectContent(TemplatePages);
