import ServerRequest from '../helpers/ServerRequest';
import { ServerUrl } from '../constants/mainUrls';
import { useQueryClient, useQuery,useMutation,  } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { create, all } from 'mathjs';
import { toast } from 'react-toastify';
const math = create(all);
export const DeleteRenseigner = async ({ param }) => {
  console.log("DeleteRenseigner ", param)

  await ServerRequest.post(`${ServerUrl}/api/rens/delrens/`, { param});
 }

export const useDeleteReinseignerMutation=()=>{
 const RensMainParamState = useSelector((state) => state.RensMainParam);
 const queryClient = useQueryClient();
 return  useMutation({
   mutationFn: DeleteRenseigner,
   onSuccess: () => {
     // Invalider ou rafraîchir les données après une mutation réussie
     queryClient.invalidateQueries(['LesIndicateursRenseignes',RensMainParamState]); // Nom de la query à rafraîchir
   },
   onError: (error) => {
     console.error("Erreur dans la mutation:", error);
   }
 });
}

export const TraiterDonneesValider = async ({ idIndicateur, data }) => {  
  const promises = [];
  let somme = 0;
  console.log("TraiterDonneesValider=>data",data)
  if (Object.hasOwn(data, "sousChamps")) {
    const sousChamps = data.sousChamps;
    if (sousChamps) {
      // Créer les promesses pour chaque sous-champ
      Object.entries(sousChamps).forEach(([key, { valeur }]) => {
        const [codeIndicateur, codeSousChamp] = key.split('_');
        idIndicateur = codeIndicateur;
        let toSave = {
          Indicateurs_id: codeIndicateur,
          SousChamp_ID: codeSousChamp,
          Entiter_id: data.Entiter,
          User_id: data.User_id,
          PTA_id: data.AnneePta,
          Valeur: valeur
        };
        somme += valeur;
        promises.push(ServerRequest.post(`${ServerUrl}/api/rens/upsert/`, { toSave }));
      });

      // Attendre toutes les promesses
      await Promise.all(promises);

      // Sauvegarder les données agrégées
      let toSave2Indic = {
        Indicateurs_id: idIndicateur,
        SousChamp_ID: "0",
        Entiter_id: data.Entiter,
        User_id: data.User_id,
        PTA_id: data.AnneePta,
        Valeur: somme
      };
      await ServerRequest.post(`${ServerUrl}/api/rens/upsert/`, { toSave: toSave2Indic });
    }
 }else {
    // Enregistrement sans sous-champ
    let toSave = {
      Indicateurs_id: data.Indicateur.idIndicateur,
      SousChamp_ID: "0",
      Entiter_id: data.Entiter,
      User_id: data.User_id,
      PTA_id: data.AnneePta,
      Valeur: data.Indicateur.valeur
    };
    await ServerRequest.post(`${ServerUrl}/api/rens/upsert/`, { toSave });
  }
};

export const useSaveReinseignerMutation=()=>{
  const RensMainParamState = useSelector((state) => state.RensMainParam);
  const queryClient = useQueryClient();
  return  useMutation({
    mutationFn: TraiterDonneesValider,
    onSuccess: () => {
      // Invalider ou rafraîchir les données après une mutation réussie
      toast.success('Enregistrement effectué avec succes')
      queryClient.invalidateQueries(['LesIndicateursRenseignes',RensMainParamState]); // Nom de la query à rafraîchir
    },
    onError: (error) => {
      console.error("Erreur dans la mutation:", error);
      toast.error('Une erreur est survenue');
    }
  });
}

function indexerIndicateurs(data) {
  const index = {};
  data.forEach(indicateur => {
      index[indicateur.idIndicateur] = indicateur;
  });
  return index;
}


function evaluerFormule(formule, indicateurs) {
  const result = { sousChampsValues: {}, sommeSousChamp: 0 };

  const sousChampKeys = Object.keys(indicateurs[Object.keys(indicateurs)[0]].sousChampsValues);

  // Extraire uniquement les identifiants d’indicateurs qui existent dans `indicateurs`
 // const matches = (formule.match(/ps2I\d+\w*/g) || []).filter(id => id in indicateurs);
  const matches = (formule.match(/ps2I\d+[a-zA-Z]\b/g) || []).filter(id => id in indicateurs);// cette ligne est meilleure
  sousChampKeys.forEach(key => {
      const scope = {};

      matches.forEach(id => {
          if (indicateurs[id] && indicateurs[id].sousChampsValues[key] !== undefined) {
              scope[id] = indicateurs[id].sousChampsValues[key];
          } else {
              console.warn(`Indicateur ou sous-champ manquant: ${id} pour le sous-champ ${key}`);
              scope[id] = 0;
          }
      });

      try {
          result.sousChampsValues[key] = math.evaluate(formule, scope);
      } catch (error) {
          console.error(`Erreur d'évaluation pour le sous-champ ${key}:`, error);
          result.sousChampsValues[key] = null;
      }
  });

  const scopeSomme = {};

  matches.forEach(id => {
      if (indicateurs[id]) {
          scopeSomme[id] = indicateurs[id].sommeSousChamp;
      } else {
          console.warn(`Indicateur manquant pour la somme globale: ${id}`);
          scopeSomme[id] = 0;
      }
  });

  try {
      result.sommeSousChamp = math.evaluate(formule, scopeSomme);
  } catch (error) {
      console.error("Erreur d'évaluation de la somme globale:", error);
      result.sommeSousChamp = null;
  }

  return result;
}

function evaluerFormuleV2(formule, indicateurs) {
  const values = {};
  console.log(Array.isArray(indicateurs));
  console.log(indicateurs);
  console.log("c'est un tableau")
    const matches = formule.match(/ps2I\d+[a-zA-Z]\b/g) || [];
     for (const match of matches) {
         const indic = indicateurs.find(indic => indic.idIndicateur === match);
         
         if (!indic) {
             console.warn(`Indicateur ${match} non trouvé dans les données.`);
             continue;
         }
   
         if (indic.sousChampsValues) {
             // Si des sous-champs existent, traiter chaque sous-champ
             for (const [sousChampKey, sousChampValue] of Object.entries(indic.sousChampsValues)) {
                 values[sousChampKey] = values[sousChampKey] || {};
                 values[sousChampKey][match] = sousChampValue;
             }
         } else if (indic.indicValue !== undefined) {
             // Si pas de sous-champs, utiliser indicValue
             values[match] = indic.indicValue;
         } else {
             console.warn(`Aucune valeur trouvée pour l'indicateur ${match}`);
         }
     }
   
     const result = {};
   
     if (Object.keys(values).length > 0 && typeof values[Object.keys(values)[0]] === 'object') {
         // Cas où il y a des sous-champs
         for (const sousChampKey of Object.keys(values)) {
             try {
                 result[sousChampKey] = math.evaluate(formule, values[sousChampKey]);
             } catch (error) {
                 console.error(`Erreur d'évaluation pour le sous-champ ${sousChampKey}:`, error);
             }
         }
     } else {
         // Cas sans sous-champs
         try {
             result.value = math.evaluate(formule, values);
         } catch (error) {
             console.error(`Erreur d'évaluation pour l'indicateur principal:`, error);
         }
     }
   
     return result;
    }
 /*  if (Array.isArray(indicateurs)) {
    
     } else {
      console.log("c'est un objet")
      // Cas sans sous-champs, indicateurs est un objet simple
      const matches = formule.match(/ps2I\d+[a-zA-Z]\b/g) || [];

      for (const match of matches) {
          if (indicateurs[match] !== undefined) {
              values[match] = indicateurs[match].indicValue;
          } else {
              console.warn(`Indicateur ${match} non trouvé dans les données.`);
          }
      }

      // Evaluation directe de la formule sans sous-champs
      try {
          return { value: math.evaluate(formule, values) };
      } catch (error) {
          console.error(`Erreur d'évaluation pour l'indicateur principal:`, error);
          return { value: null };
      }
  } */

    export   function evaluerFormuleV3(formule, indicateurs) {
      const values = {};
      const globalValues = {}; // Pour stocker les sommes sous-champs de chaque indicateur
  
      const matches = formule.match(/ps2I\d+[a-zA-Z]\b/g) || [];
  
      for (const match of matches) {
          const indic = indicateurs.find(indic => indic.idIndicateur === match);
  
          if (!indic) {
              console.warn(`Indicateur ${match} non trouvé dans les données.`);
              continue;
          }
  
          // Récupérer sommeSousChamp pour la valeur globale si elle est définie
          if (indic.sommeSousChamp !== undefined) {
              globalValues[match] = indic.sommeSousChamp;
          }
  
          // Traiter chaque sous-champ s'ils existent
          if (indic.sousChampsValues) {
              for (const [sousChampKey, sousChampValue] of Object.entries(indic.sousChampsValues)) {
                  values[sousChampKey] = values[sousChampKey] || {};
                  values[sousChampKey][match] = sousChampValue;
              }
          } else if (indic.indicValue !== undefined) {
              // Utiliser indicValue si pas de sous-champs
              values[match] = indic.indicValue;
          } else {
              console.warn(`Aucune valeur trouvée pour l'indicateur ${match}`);
          }
      }
  
      const result = { globalValue: null, sousChampsValues: {} };
  
      // Calcul de la valeur globale en utilisant les sommes sous-champs
      try {
          if (Object.keys(globalValues).length > 0) {
              result.globalValue = math.evaluate(formule, globalValues);
          }
      } catch (error) {
          console.error("Erreur d'évaluation pour la valeur globale:", error);
      }
  
      // Calcul des valeurs par sous-champ
      for (const sousChampKey of Object.keys(values)) {
          try {
              result.sousChampsValues[sousChampKey] = math.evaluate(formule, values[sousChampKey]);
          } catch (error) {
              console.error(`Erreur d'évaluation pour le sous-champ ${sousChampKey}:`, error);
          }
      }
  
      return result;
    }
    ///
  export function evaluerFormuleV4(formule, indicateurs) {
    const values = {};
    const globalValues = {}; // Stocke les valeurs pour une évaluation globale

    // Extraction des codes d'indicateurs dans la formule
    const matches = formule.match(/ps2I\d+[a-zA-Z]\b/g) || [];
    for (const match of matches) {
        const indic = indicateurs.find(indic => indic.idIndicateur === match);
        if (!indic) {
            console.warn(`Indicateur ${match} non trouvé dans les données.`);
            continue;
        }
        if (indic.sousChampsValues) {
            // Cas avec sous-champs : ajout de chaque sous-champ dans `values`
            for (const [sousChampKey, sousChampValue] of Object.entries(indic.sousChampsValues)) {
                if (!values[sousChampKey]) values[sousChampKey] = {};
                values[sousChampKey][match] = sousChampValue;
            }
            // Stocker également la valeur globale sommeSousChamp si présente
            if (indic.sommeSousChamp !== undefined) {
                globalValues[match] = indic.sommeSousChamp;
            }
        } else if (indic.indicValue !== undefined) {
            // Cas sans sous-champs : ajout dans `globalValues`
            globalValues[match] = indic.indicValue;
        } else {
            console.warn(`Aucune valeur trouvée pour l'indicateur ${match}`);
        }
    }
    const result = {};
    // Évaluation pour chaque sous-champ si présents
    if (Object.keys(values).length > 0) {
        result.sousChampsValues = {};
        for (const sousChampKey in values) {
            try {
                result.sousChampsValues[sousChampKey] = math.evaluate(formule, values[sousChampKey]);
            } catch (error) {
                console.error(`Erreur d'évaluation pour le sous-champ ${sousChampKey}:`, error);
            }
        }
    }

    // Évaluation de la valeur globale pour `sommeSousChamp` ou `indicValue`
    if (Object.keys(globalValues).length > 0) {
        try {
            result.globalValue = math.evaluate(formule, globalValues);
        } catch (error) {
            console.error(`Erreur d'évaluation pour la valeur globale:`, error);
        }
    }

    return result;
  }
  ///
  function evaluerFormuleV5(formule, indicateurs) {
    const values = {};
    const globalValues = {}; // Stocke les valeurs globales des indicateurs pour l'évaluation de formule

    // Extraction des codes d'indicateurs dans la formule
    const matches = formule.match(/ps2I\d+[a-zA-Z]\b/g) || [];
    for (const match of matches) {
        const indic = indicateurs.find(indic => indic.idIndicateur === match);
        if (!indic) {
            console.warn(`Indicateur ${match} non trouvé dans les données.`);
            continue;
        }
        if (indic.sousChampsValues) {
            // Cas avec sous-champs : ajout de chaque sous-champ dans `values`
            for (const [sousChampKey, sousChampValue] of Object.entries(indic.sousChampsValues)) {
                if (!values[sousChampKey]) values[sousChampKey] = {};
                values[sousChampKey][match] = sousChampValue;
            }
            // Stocker également la valeur globale de l'indicateur
            if (indic.sommeSousChamp !== undefined) {
                globalValues[match] = indic.sommeSousChamp;
            }
        } else if (indic.indicValue !== undefined) {
            // Cas sans sous-champs : ajout dans `globalValues`
            globalValues[match] = indic.indicValue;
        } else {
            console.warn(`Aucune valeur trouvée pour l'indicateur ${match}`);
        }
    }

    const result = {};

    // Évaluation pour chaque sous-champ si présents
    if (Object.keys(values).length > 0) {
        result.sousChampsValues = {};
        for (const sousChampKey in values) {
            try {
                result.sousChampsValues[sousChampKey] = math.evaluate(formule, values[sousChampKey]);
            } catch (error) {
                console.error(`Erreur d'évaluation pour le sous-champ ${sousChampKey}:`, error);
            }
        }
    }

    // Calcul de la valeur globale avec la formule appliquée aux `globalValues`
    if (Object.keys(globalValues).length > 0) {
        try {
            result.globalValue = math.evaluate(formule, globalValues);
        } catch (error) {
            console.error(`Erreur d'évaluation pour la valeur globale:`, error);
        }
    }

    return result;
}

      
export const useUpdateMainIndic=()=> {
  const queryClient = useQueryClient();
  const  updateMainIndic=async(items, AnneePta, Entiter, User_id)=>{
    let valeurDesChamps=await Promise.all(
      items.map(async(item)=>{
        if (item.IndicateurSousChamp?.length > 0){
          const sousChamps = await fetchSousChampValuesV2(item.idIndicateur, item.IndicateurSousChamp, Entiter, User_id, AnneePta);
         // const sommeValeurs = Object.values(sousChamps).reduce((acc, el) => acc + el.valeur, 0);
         const sousChampsNumeriques = Object.fromEntries(
          Object.entries(sousChamps).map(([key, valeur]) => [key, Number(valeur)])
      );
         const sommeValeurs = item.Formule
         ? null
         : Object.values(sousChampsNumeriques).reduce((acc, valeur) => acc + valeur, 0);

         return {
            idIndicateur:item.idIndicateur,
            sousChampsValues:sousChamps,
            sommeSousChamp:sommeValeurs
          }
        } else { //Pas de sous champs
         let value=await fetchIndicValue(item.idIndicateur,Entiter,User_id,AnneePta)
         return {
          idIndicateur:item.idIndicateur,
          indicValue:value
         }
        }
      })
    )
    let  withoutLastChar = items[0].idIndicateur.slice(0, -1);
    let mainIndic= withoutLastChar + "c";
    const ListeIndic= queryClient.getQueryData(['LesIndicPsEnCours'])
    let item=ListeIndic?.find((el=>  el.idIndicateur===mainIndic))  
    const resultat = evaluerFormuleV4(item.Formule, valeurDesChamps);
    console.log("valeurDesChamps = ",valeurDesChamps)
    resultat.mainIndic=mainIndic;
    console.log("Résultat final=",resultat)
    return resultat
  }
 return {updateMainIndic}
}



export const SaveMainIndic=async ({datas})=>{
  console.log('SaveMainIndic datas',datas)
  const {data}= await ServerRequest.post(`${ServerUrl}/api/rens/savemainindic/`,{datas})
  console.log('SaveMainIndic data after api call',data)
  return data
}
export const useSaveMainIndicMutation=()=>{
  const queryClient = useQueryClient();
  const RensMainParamState = useSelector((state) => state.RensMainParam);
  return  useMutation({
    mutationFn: SaveMainIndic,
    onSuccess: () => {
      // Invalider ou rafraîchir les données après une mutation réussie
      toast.success('Indicateur principal enregistré avec succes')
      queryClient.invalidateQueries(['LesIndicateursRenseignes',RensMainParamState]); // Nom de la query à rafraîchir
    },
    onError: (error) => {
      console.error("Erreur dans la mutation:", error);
      toast.error('Une erreur est survenue');
    }
  });
}
export const GetRensValue = async(param )=> {
  const {data}= await ServerRequest.post(`${ServerUrl}/api/rens/value/`,{param})
        //console.log("data  ",data)
      return data;
}
export const GetAllRens4User = async({queryKey })=> {
  const [,param]= queryKey;
  console.log('param ',param)
  const {data}= await ServerRequest.post(`${ServerUrl}/api/rens/rens4user/`,{idUser:param.User_id})
        //console.log("data  ",data)
      return data;
}

export const GetAllRensPrisma = async({queryKey })=> {
  const [,param]= queryKey;

  console.log('param ***',param)
  
  const {data}= await ServerRequest.post(`${ServerUrl}/api/rens/allrenspxma/`,{param})
        //console.log("data  ",data)
      return data;
}



export const GetAllRens = async (param) => {
  const { data } = await ServerRequest.post(
    `${ServerUrl}/api/rens/allrens/`,
    param
  );
  return data;
};
export const GetAllRensV2 = async (param) => {
  const { data } = await ServerRequest.post(
    `${ServerUrl}/api/rens/allrensV2/`,
    param
  );
  return data;
};
export const GenererRenseigner = async (param) => {
  const { data } = await ServerRequest.post(`${ServerUrl}/api/rens/generer/`);
  return data;
};
export const UpdateRens = async (param) => {
  console.log('UpdateRens ', param);
  const { data } = await ServerRequest.post(`${ServerUrl}/api/rens/up/`, param);
  return data;
};
export const GetRens = async (param) => {
  const { data } = await ServerRequest.post(`${ServerUrl}/api/rens/id/`, param);
  return data[0];
};

export const gen4user = async (param) => {
  const { data } = await ServerRequest.post(`${ServerUrl}/api/rens/gen4user/`, param );
  return data;
};


/*extractFieldsFromFormula*/
export const extractFieldsFromFormula = (formule) => {
  const fieldRegex = /\bps[a-zA-Z0-9]*\b/g;
  return formule.match(fieldRegex) || [];
};

export const  fetchValuesForFields = async (fields,Entiter,User_id, AnneePta) => {
  const values = {};
  for (const field of fields) {
    var param={
      User_id:User_id,
      Entiter:Entiter,
      AnneePta:AnneePta,
      Indicateur:field,
      sousChamp:"0"
    }
    const record = await GetRensValue(param)
    if (record) {
      values[field] = record.Valeur;
    } else {
      values[field] = 0;
    }
  }
  return values;
};

/** */
export const extractDenominators = (formula) => {
  const denomRegex = /\/\s*([a-zA-Z0-9]+)/g; // Regex pour capturer les dénominateurs
  const denominators = [];
  let match;
  while ((match = denomRegex.exec(formula)) !== null) {
    denominators.push(match[1]); // Ajoute le dénominateur à la liste
  }
  return denominators;
};

export const applyFormula = (formule, values) => {
  if (!formule || !values) {
    console.warn('Formule ou valeurs manquantes.'); // Ajout d'une vérification pour éviter l'erreur
    return null;
  }
  let formulaToEvaluate = formule;

  for (const [field, value] of Object.entries(values)) {
    const regex = new RegExp(`\\b${field}\\b`, 'g');
    formulaToEvaluate = formulaToEvaluate.replace(regex, value);
  }

   // Vérification des dénominateurs
const denominators = extractDenominators(formulaToEvaluate); // Créez une fonction pour extraire les dénominateurs

for (const denom of denominators) {
  if (values[denom] === 0) {
    console.warn(`La valeur de ${denom} est zéro, évaluation de la formule annulée.`);
    return null; // Ou une valeur par défaut appropriée
  }
}
  try {
    const math = create(all);
    const result = math.evaluate(formulaToEvaluate);
    return result;
  } catch (error) {
    console.error('Erreur lors de l’évaluation de la formule:', error);
    return null;
  }
};

export const fetchIndicValue=async(idIndicateur,Entiter,User_id,AnneePta)=>{
  let param={
    User_id:User_id,
    Entiter:Entiter,
    AnneePta:AnneePta,
    Indicateur:idIndicateur,
    sousChamp:"0",
  }
  const record = await GetRensValue(param)
  return record ? record.valeur :0
}


export const fetchSousChampValues = async (idIndicateur, IndicateurSousChamp, Entiter, User_id, AnneePta) => {
  const sousChampPromises = IndicateurSousChamp?.map(async (sousChamp) => {
    const param = {
      User_id,
      Entiter,
      AnneePta,
      Indicateur: idIndicateur,
      sousChamp: sousChamp.sousChamp.idSousChamp,
    };
    
    const record = await GetRensValue(param);
    const key = `${idIndicateur}_${sousChamp.sousChamp.idSousChamp}`; // Clé unique combinant idIndicateur et idSousChamp
    const sousChampData = {
    //  idSousChamp: sousChamp.sousChamp.idSousChamp, // ID du sous-champ
      valeur: record ? record.valeur : 0, // Valeur obtenue ou 0 par défaut
    };
    
    return {
      [`${key}`]: sousChampData
    };
  });

  // Attente de toutes les promesses en parallèle
  const sousChampArray = await Promise.all(sousChampPromises);

  return sousChampArray.reduce((acc, curr) => {
    return { ...acc, ...curr };
  }, {});
};
export const fetchSousChampValuesV2 = async (idIndicateur, IndicateurSousChamp, Entiter, User_id, AnneePta) => {
  const sousChampPromises = IndicateurSousChamp?.map(async (sousChamp) => {
    const param = {
      User_id,
      Entiter,
      AnneePta,
      Indicateur: idIndicateur,
      sousChamp: sousChamp.sousChamp.idSousChamp,
    };
    
    const record = await GetRensValue(param);
    //const key = `${idIndicateur}_${sousChamp.sousChamp.idSousChamp}`; // Clé unique combinant idIndicateur et idSousChamp
    const key = `${sousChamp.sousChamp.idSousChamp}`; // 
    const sousChampData = {
    //  idSousChamp: sousChamp.sousChamp.idSousChamp, // ID du sous-champ
      valeur: record ? record.valeur : 0, // Valeur obtenue ou 0 par défaut
    };
    
    return {
     // [`${key}`]: sousChampData
     [`${key}`]: record ? record.valeur : 0
    };
  });

  // Attente de toutes les promesses en parallèle
  const sousChampArray = await Promise.all(sousChampPromises);

  return sousChampArray.reduce((acc, curr) => {
    return { ...acc, ...curr };
  }, {});
};