import 'bootstrap/dist/css/bootstrap.css';
import { BrowserRouter } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import { Provider } from 'react-redux';
import MainApp from './MainApp';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './index.css';
import { store } from './store/rootReducer';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { QueryClient } from "@tanstack/react-query";
import { createRoot } from 'react-dom/client';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 15 * (60 * 1000),
      cacheTime: 20 * (60 * 1000),
      networkMode: "always",
    },
    mutations: { networkMode: "always" },
  },
});

const container = document.getElementById('root');
const root = createRoot(container); 
root.render(
  <Provider store={store}>
    <QueryClientProvider client={queryClient}>
    
    <ToastContainer
      position="top-center"
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      raggable
      pauseOnHover
    />
    <SnackbarProvider maxSnack={5}>
      <BrowserRouter>
        <MainApp />
      </BrowserRouter>
    </SnackbarProvider>
    <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  </Provider>
);
