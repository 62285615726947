import { ServerUrl } from "../constants/mainUrls";
import ServerRequest from "../helpers/ServerRequest";

export const fetchAllIndicateursPrisma= async () => {
        const {data}= await ServerRequest.post(`${ServerUrl}/api/indic/all/`)
        //console.log("data  ",data)
      return data;
    };



    