import ServerRequest from '../helpers/ServerRequest';
import { ServerUrl } from '../constants/mainUrls';
export const GetAllPta= async () => {
    const {data}= await ServerRequest.post(`${ServerUrl}/api/pta/all/`)
   // console.log("/api/pta/all/ ",data)
   return data;
};




export const GetPtaByYear = async({queryKey })=> {
    const [,param]= queryKey;
    var Annee=new Date().getFullYear().toString() 
    console.log('param ',param)
    const {data}= await ServerRequest.post(`${ServerUrl}/api/pta/year/`,{year:Annee})
          //console.log("data  ",data)
        return data;
  }