import * as React from 'react';
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const AlertMessage = () => {
  const { message, globalDialogVisible, typeMessage } = useSelector(
    (state) => state.globalDialogStatus
  );
  /*  const message= useSelector((state) => state.globalDialogStatus?.message);
 const globalDialogVisible= useSelector((state) => state.globalDialogStatus?.globalDialogVisible);
 const typeMessage= useSelector((state) => state.globalDialogStatus?.typeMessage); */

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    toast.info(message);
  };
  var TypeDialogue;
  if (typeMessage) TypeDialogue = typeMessage;
  else TypeDialogue = 'error';

  // valeurs possibles de everity="error", "warning" ,  "info" , "success"
  var vertical = 'bottom',
    horizontal = 'right';
  return (
    <Stack spacing={2} sx={{ width: '100%' }}>
      <Snackbar
        open={globalDialogVisible}
        autoHideDuration={5000}
        anchorOrigin={{ vertical, horizontal }}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity={TypeDialogue}
          sx={{ width: '100%' }}
        >
          {message}
        </Alert>
      </Snackbar>
    </Stack>
  );
};
export default AlertMessage;
