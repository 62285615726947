import { useState } from 'react';
import Selection from './Selection';
import EditForm from './EditForm';

const Principal = () => {
  const [selectedItems, setSelectedItems] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isEditing, setIsEditing] = useState(false);
  const [editResults, setEditResults] = useState([]);

  const handleSelectionSubmit = (items) => {
    setSelectedItems(items); // Définit la liste entière, incluant l'élément principal et les éléments associés
    setIsEditing(true);
    setCurrentIndex(0); // Réinitialiser à zéro pour commencer avec le premier élément de la liste
  };

  const handleEditSubmit = (data) => {
    setEditResults((prevResults) => [...prevResults, data]);

    if (currentIndex < selectedItems.length - 1) {
      setCurrentIndex(currentIndex + 1); // Avancer à l’élément suivant
    } else {
      setIsEditing(false);
      // Affiche les résultats finaux ou autres actions après la soumission finale
    }
  };

  const handleCancel = () => {
    setIsEditing(false);
    setEditResults([]);
    setSelectedItems([]);
    setCurrentIndex(0);
  };

  const handlePrevious = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  return (
    <div>
      {!isEditing ? (
        <Selection onSubmit={handleSelectionSubmit} />
      ) : (
        <EditForm
          item={selectedItems[currentIndex]}
          onSubmit={handleEditSubmit}
          onCancel={handleCancel}

          onPrevious={handlePrevious}
          hasNext={currentIndex < selectedItems.length - 1}
          hasPrevious={currentIndex > 0}
          
        />
      )}

      {/* Affiche les résultats finaux après soumission de tous les formulaires */}
      {editResults.length > 0 && !isEditing && (
        <ul>
          {editResults.map((result, index) => (
            <li key={index}>{result.name}</li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default Principal;
