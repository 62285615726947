import React,{useEffect} from 'react'
import { Head } from 'react-static'
import caritasLogo from '../assets/images/caritas-logoV2.jpg'
//import pageBg from '../assets/images/pexels-photo-273238.jpeg'
import pageBGV2 from '../assets/images/bg_image.jpg'
import { useNavigate } from 'react-router-dom'
import { MdLogin } from "react-icons/md";
import './Home.scss'

const Home = () => {
  const naviguer=useNavigate()
  useEffect(() => {
    let currentUser=sessionStorage.getItem('currentUser')
    console.log("currentUser ",JSON.parse(currentUser))
    if (JSON.parse(currentUser)) naviguer("/bord")
   }, [])
   
  
  return (
  <div className="min-vh-100 d-flex flex-column align-items-center bg-cover bg-center bg-no-repeat" style={{ backgroundImage: `url(${pageBGV2})` }} >
    <Head>
      <title>Bienvenue | Plateforme de Suivi-Evaluation de Redevabilité et d'Apprentissage | Caritas Bénin</title>
    </Head>
    <img className="w-full max-w-xs sm:max-w-md md:max-w-lg lg:max-w-xl" src={caritasLogo} />
   
     <div className="texte-white d-flex flex-column align-items-center  bgc">  
        <h1 className='texte-white font-bold text-2xl'>Plateforme de Suivi-Evaluation de Redevabilité et d'Apprentissage</h1>
        <br />
        <p className='texte-center texte-white text-xl text-wrap'>Cette plateforme est conçue pour faciliter la transmission d'informations de suivi-évaluation et de rapportage entre les Diocèses du Bénin et le Siège National de Caritas Bénin.  Si vous êtes autorisé à y accéder, veuillez cliquer ci-dessous pour vous connecter.</p>
    
        <br />
        
      </div>  
      <div className="flex flex-row items-center justify-center">
     
      <button  className='flex justify-center items-center bg-green-800 text-white  mx-2 mt-4 p-2 font-bold rounded-lg ' onClick={()=>naviguer("login")} >
      <span className='mr-2'> <MdLogin/></span> 
      <span>Connecter</span>
        </button>
     
      <button type="button" onClick={() => naviguer("/chpsswV2")}
       className='bg-red-900 text-white font-bold rounded-lg mx-2 mt-4 p-2'   >
          Mot de passe oublié
       </button>

       <button type="button" onClick={() => naviguer("/crusV2")}
       className='bg-gray-900 text-white font-bold rounded-lg mx-2 mt-4 p-2'   >
         Créer un compte
       </button> 
      </div>

      
  </div>
)}

export default Home
