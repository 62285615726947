import React ,{useState,useEffect,useRef} from 'react'
import {  useForm,Controller } from 'react-hook-form';
import { insertUser, UserExistsV2, } from '../../DbApi/Users.DbApi';
import { toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";
import { FaEye, FaEyeSlash ,FaHome } from 'react-icons/fa';
import Topbar from '../../components/TopBar/Topbar';
import { FcApproval } from "react-icons/fc";
import NewSidebar from '../../components/NewSideBar/NewSidebar';
import { getEntiterForSelectBox } from '../../DbApi/entiter.DbApi';
import Select from 'react-select';
import '../Dashboard/TableauxDeBord.scss'


const Create_UserV2 = () => {
  const navigate = useNavigate();
  const [emailNoExists, setEmailNoExists] = useState(false);
  const [currentUser, setCurrentUser] = useState(undefined);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const { register, handleSubmit, watch, formState: { errors, isSubmitting },control } = useForm({ mode: 'onTouched' });
  const [entiters, setEntiters] = useState([]);
  
  const checkEmailExists = async (email) => {
      try {
          const response = await UserExistsV2(email);
          return response;
      } catch (error) {
          console.error("Erreur lors de la vérification de l'adresse e-mail :", error);
          return false;
      }
  };

  const onSubmit = async (data) => {

    if (data.unMalin) {
        toast.error('Soumission suspecte. Formulaire rejeté');
        return;
      }
        console.log("voici les données qui ont été soumises: ",data)
  
       const re = await insertUser(data);
       console.log("retour appel api inserUser: ",re)
      if (re.id) {
          toast.success('Compte créé avec succès');
          navigate('/');
      } else {
          toast.error('Une erreur est survenue pendant la création du compte.');
      } 
  };

  useEffect(() => {
    async function fetchData() {
      var Lesentiters = await getEntiterForSelectBox();
      setEntiters(Lesentiters);
    }
    fetchData();
  }, []);
//x
  return (
      <div className='flex flex-col overflow-y-auto h-screen items-center justify-center'>
        <div>
        <h3 className='text-center font-bold text-red-800'>Création de compte</h3>
        </div>
         
          <form onSubmit={handleSubmit(onSubmit)}>
         
                 
              {/* Champ Sélection d'Entité avec react-select et Controller de react-hook-form */}
            <div className="flex flex-col mb-2 relative">
                <label className="font-bold text-gray-700">Sélectionner une Entité :</label>
                <Controller
                    name="entiter"
                    control={control}
                    rules={{ required: "L'entité est requise" }} // Ajout de la validation required
                    render={({ field }) => (
                    <Select
                        {...field}
                        options={entiters}
                        /* getOptionLabel={(entiter) => entiter.NomEntiter}
                        getOptionValue={(entiter) => entiter.id} */
                        placeholder="Choisissez une entité"
                        className="mt-1"
                        onChange={(selected) => field.onChange(selected)}
                    />
                    )}
                />
                {errors.entiter && (
                    <p className="text-red-500 text-sm mt-1">{errors.entiter.message}</p>
                )}
            </div>
                 {/* Champ Prénom */}
                 <div className="flex flex-col mb-2 relative">
                        <label className="font-bold text-gray-700">Prénom :</label>
                        <div className="relative">
                            <input
                                type={"text"}
                                {...register("Prenom", {
                                    required: "Le prénom est requis"
                                })}
                                className={`w-full mt-1 p-2 pr-10 border ${errors.Prenom ? 'border-red-500' : 'border-gray-300'} rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500`}
                                style={{ height: '2.5rem' }} // Hauteur fixe pour stabiliser l'input
                            />
                          
                        </div>
                        {errors.Prenom && <p className="text-red-500 text-sm mt-1">{errors.Prenom.message}</p>}
                    </div>       
                        {/* Champ Nom */}
                        <div className="flex flex-col mb-2 relative">
                        <label className="font-bold text-gray-700">Nom :</label>
                        <div className="relative">
                            <input
                                type={"text"}
                                {...register("Nom", {
                                    required: "Le Nom est requis"
                                })}
                                className={`w-full mt-1 p-2 pr-10 border ${errors.Nom ? 'border-red-500' : 'border-gray-300'} rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500`}
                                style={{ height: '2.5rem' }} // Hauteur fixe pour stabiliser l'input
                            />
                          
                        </div>
                        {errors.Nom && <p className="text-red-500 text-sm mt-1">{errors.Nom.message}</p>}
                    </div>  
                 
                  {/* Champ Adresse email */}
                  <div className='flex flex-col'>
                      <label htmlFor="email" className="font-light">Adresse-mail</label>
                      <input
                          type="email"
                          {...register("email", {
                              required: "Une adresse e-mail est requise",
                              validate: async (value) => {
                                  const NonExists = ! await checkEmailExists(value);
                                  setEmailNoExists(NonExists)
                                  
                                  return NonExists || "Cette adresse e-mail existe déjà dans notre base de données.";
                              }
                          })}
                          className={`mt-1 p-2 border ${errors.email ? 'border-red-500' : 'border-gray-300'} rounded-md`}
                      />
                      {errors.email && <p className="text-red-500 text-sm mt-1">{errors.email.message}</p>}
                  </div>
                                
                    {/* Champ mot de passe */}
                    <div className="flex flex-col mb-2 relative">
                        <label className="font-bold text-gray-700">Mot de passe :</label>
                        <div className="relative">
                            <input
                                type={showPassword ? "text" : "password"}
                                {...register("password", {
                                    required: "Le mot de passe est requis"
                                })}
                                className={`w-full mt-1 p-2 pr-10 border ${errors.password ? 'border-red-500' : 'border-gray-300'} rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500`}
                                style={{ height: '2.5rem' }} // Hauteur fixe pour stabiliser l'input
                            />
                            <button
                                type="button"
                                onClick={() => setShowPassword(!showPassword)}
                                className="absolute right-2 top-1/2 -translate-y-1/2 text-gray-500 hover:text-gray-700"
                            >
                                {showPassword ? <FaEyeSlash /> : <FaEye />}
                            </button>
                        </div>
                        {errors.password && <p className="text-red-500 text-sm mt-1">{errors.password.message}</p>}
                    </div>
                    <input
                        type="hidden"
                        {...register('unMalin')} // Enregistre le champ caché avec RHF
                    />
                  <div className='flex items-center justify-center mt-6'>
                      <button
                          type="button"
                          onClick={() => navigate("/")}
                          className='flex items-center mx-2 p-2 bg-gray-600 text-white font-bold rounded-lg text-center'
                      >
                          <FaHome className='mr-2' /> Page d'accueil
                      </button>
                      <button
                          type="submit"
                          /* disabled={isSubmitting || emailNoExists} */
                          className="flex items-center mx-2 p-2 bg-green-800 text-white font-semibold rounded-md hover:bg-green-600 disabled:opacity-50"
                      >
                          <FcApproval className='mr-2' /> {isSubmitting ? 'Vérification...' : 'Soumettre'}
                      </button>
                  </div>
          </form>
      </div>
  );
};

const CreateUserV2 = () => {
  return (
      <>
          <div className="row">
              <Topbar user={null} />
          </div>
          <div className="board">
              <NewSidebar />
              <div className="boardContainer toutHauteur">
                  <Create_UserV2 />
              </div>
          </div>
      </>
  );
};

export default CreateUserV2;